import { useMemo } from "react";
import { fromMM } from "../Utils/frameUtils";
import { buildOzonePipe, buildPanel } from "../Utils/geometryUtils";
import OzoneProperties from "./Properties/OzoneProperties";
import { Edges } from "@react-three/drei";
import { useStore } from "../Store/zustandStore";
import * as THREE from "three";

export default function Ozone({ frame }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);

  const { ozone } = frame;

  const frameLength = fromMM(frame.length);
  const frameHeight = fromMM(frame.height);
  const frameWidth = fromMM(frame.width);

  const bottom_offset = fromMM(150);

  const boxLength = fromMM(ozone?.length || OzoneProperties.boxLength);
  const boxHeight = fromMM(ozone?.height || OzoneProperties.boxHeight);
  const boxWidth = fromMM(ozone?.width || OzoneProperties.boxWidth);

  const pipeWidth = boxLength * 1.25;
  const pipeHeight = boxHeight;
  const tubeThickness = 0.025;
  const intrudingLength = frameWidth / 4;

  const sprayRadius = 0.1;
  const sprayLength = 0.2;

  const boxPosition = [0, -frameHeight / 2 + boxHeight / 2 + bottom_offset, -frameWidth / 2 - boxWidth / 2 - frameThickness];
  const pipePosition = [boxPosition[0], boxPosition[1] + boxHeight / 2, boxPosition[2]];
  const sprayPosition = [0, boxPosition[1] + boxHeight - 0.03 - tubeThickness, -frameWidth / 2 + sprayLength / 2 + intrudingLength];

  const geom = useMemo(() => {
    return buildPanel(boxLength, boxHeight, boxWidth);
  }, [boxLength, boxHeight, boxWidth]);

  const pipeGeom = useMemo(() => {
    return buildOzonePipe(pipeWidth, pipeHeight, tubeThickness, boxWidth / 2, frameThickness + intrudingLength);
  }, [pipeWidth, pipeHeight, tubeThickness, boxWidth, frameThickness]);

  return (
    <group>
      <mesh geometry={geom} position={boxPosition} receiveShadow castShadow>
        <meshStandardMaterial color="Grey" />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>

      <mesh geometry={pipeGeom} position={pipePosition} receiveShadow castShadow>
        <meshStandardMaterial color="Black" side={THREE.DoubleSide} />
        {/* <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" depthTest={true} />
        </Edges> */}
      </mesh>

      <mesh rotation={[-Math.PI / 2, 0, 0]} position={sprayPosition} receiveShadow castShadow>
        <coneGeometry args={[sprayRadius, sprayLength, 8]} />
        <meshLambertMaterial color={new THREE.Color("#76d0ff")} transparent opacity={0.5} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#76d0ff" depthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
