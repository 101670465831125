import * as THREE from "three";

import { CSG } from "@enable3d/three-graphics/jsm/csg";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { DEFAULT_EMPTY_THICKNESS } from "../Constants";
import { fromMM } from "./frameUtils";

export const buildHollowFrame = (width, height, depth, thickness) => {
  const shape = new THREE.Shape();
  let holePath = new THREE.Path();
  let extrudeSettings = {
    depth: width,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };

  const offsetWidth = depth / 2;
  const offsetHeight = height / 2;

  shape
    .moveTo(0 - offsetWidth, 0 - offsetHeight)
    .lineTo(0 - offsetWidth, height - offsetHeight)
    .lineTo(depth - offsetWidth, height - offsetHeight)
    .lineTo(depth - offsetWidth, 0 - offsetHeight)
    .lineTo(0 - offsetWidth, 0 - offsetHeight);

  holePath
    .moveTo(thickness - offsetWidth, thickness - offsetHeight)
    .lineTo(thickness - offsetWidth, height - thickness - offsetHeight)
    .lineTo(depth - offsetWidth - thickness, height - thickness - offsetHeight)
    .lineTo(depth - offsetWidth - thickness, thickness - offsetHeight)
    .lineTo(thickness - offsetWidth, thickness - offsetHeight);

  shape.holes.push(holePath);

  let extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeRotationY(Math.PI / 2));
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(-width / 2, 0, 0));

  return extrudeGeometry;
};

export const buildPipe = (radius, thickness, length) => {
  const shape = new THREE.Shape();
  var holePath = new THREE.Path();

  shape.absarc(0, 0, radius, 0, Math.PI * 2, 0, false);
  holePath.absarc(0, 0, radius - thickness, 0, Math.PI * 2, true);

  shape.holes.push(holePath);

  let extrudeSettings = {
    depth: length,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 6,
  };
  let extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

  return extrudeGeometry;
};

function createBentDuctShape(xRadius, yRadius, width, height, thickness) {
  const extendDown = height - yRadius;
  const extentLeft = width - xRadius;

  var curve = new THREE.EllipseCurve(0, 0, xRadius, yRadius, 0, Math.PI / 2, false);

  var points = curve.getSpacedPoints(20);

  points.splice(0, 0, new THREE.Vector2(width - extentLeft, -extendDown - thickness)); // Starting point

  //
  points.push(new THREE.Vector2(-extentLeft - thickness, height - extendDown));
  points.push(new THREE.Vector2(-extentLeft - thickness, 0));

  // corner point
  points.push(new THREE.Vector2(0, 0));

  // Finish point to join first added point
  points.push(new THREE.Vector2(0, -extendDown - thickness));

  const shape = new THREE.Shape();
  shape.setFromPoints(points);

  return shape;
}

export function buildOdourPipe(width, height, tubeThickness, distanceToFrame, intrudingLength, downwardSlope) {
  width -= tubeThickness * 2;
  //height -= tubeThickness * 2;

  const roundedHeight = 0.05;

  function ccc(t) {
    const t2 = 2 * Math.PI * t * 0.5;
    const x = -Math.cos(t2);
    const z = -Math.sin(t2);
    const y = t;
    return new THREE.Vector3(x * (width / 2), y * -roundedHeight, z * (width / 2));
  }

  let curve = [];
  let numberOfPoints = 30;

  for (let i = 0; i <= numberOfPoints; i++) {
    curve.push(ccc(i / numberOfPoints));
  }

  const uplift = 0.0;
  const gap = width / 2;

  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y + uplift, curve[0].z + gap * 0.4));
  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y - downwardSlope, curve[0].z + gap * 0.6));
  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y, curve[0].z + (distanceToFrame - width / 2)));
  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y, curve[0].z + intrudingLength));

  let lastPoint = curve[curve.length - 1].clone();
  curve.push(lastPoint.set(lastPoint.x, lastPoint.y - (height - roundedHeight), lastPoint.z));

  const spline = new THREE.CatmullRomCurve3(curve);

  let tubeGeometry = new THREE.TubeGeometry(spline, 300, tubeThickness, 10, false);
  tubeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(width / 2, height / 2, 0));

  return tubeGeometry;
}

export function buildOzonePipe(width, height, tubeThickness, distanceToFrame, intrudingLength) {
  width -= tubeThickness * 2;
  height -= tubeThickness * 2;

  function ccc(t) {
    const t2 = 2 * Math.PI * t * 0.75;
    const x = -Math.cos(t2);
    const z = -Math.sin(t2);
    const y = t;
    return new THREE.Vector3(x * (width / 2), y * -height, z * (width / 2));
  }

  let curve = [];
  let numberOfPoints = 5;

  for (let i = 0; i <= numberOfPoints; i++) {
    curve.push(ccc(i / numberOfPoints));
  }

  const uplift = 0.015;
  const downwardSlope = 0.045;
  const gap = width / 2;

  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y + uplift, curve[0].z + gap * 0.4));
  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y - downwardSlope, curve[0].z + gap * 0.6));
  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y, curve[0].z + distanceToFrame));
  curve.unshift(curve[0].clone().set(curve[0].x, curve[0].y, curve[0].z + intrudingLength));

  let lastPoint = curve[curve.length - 1].clone();
  curve.push(lastPoint.set(lastPoint.x - 0.05, lastPoint.y, lastPoint.z));

  const spline = new THREE.CatmullRomCurve3(curve);

  let tubeGeometry = new THREE.TubeGeometry(spline, 300, tubeThickness, 10, false);
  tubeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(width / 2, height / 2, -width / 2));

  return tubeGeometry;
}

export function buildCurvedDuct(xRadius, yRadius, width, height, depth, thickness) {
  let extendDown = height - yRadius;
  let extentLeft = width - xRadius;
  let shape = createBentDuctShape(xRadius, yRadius, width, height, 0);

  let extrudeSettings = {
    depth: depth,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };
  let extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(-width / 2 + extentLeft, -height / 2 + extendDown, -depth / 2));
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeRotationY(Math.PI / 2));
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI));

  if (thickness) {
    let mesh = new THREE.Mesh(extrudeGeometry);

    xRadius -= thickness;
    yRadius -= thickness;
    width -= thickness;
    height -= thickness;
    depth -= thickness;
    shape = createBentDuctShape(xRadius, yRadius, width, height, width * 2);

    extrudeSettings = {
      depth: depth,
      steps: 1,
      bevelEnabled: false,
      curveSegments: 16,
    };
    extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(-width / 2 + extentLeft, -height / 2 + extendDown, -depth / 2));
    extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeRotationY(Math.PI / 2));
    extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI));

    let subtractMesh = new THREE.Mesh(extrudeGeometry);

    let newMesh = CSG.subtract(mesh, subtractMesh);
    return newMesh.geometry;
  }

  return extrudeGeometry;
}

export const buildDuct = (height, depth, radial, holeWidth, holeHeight, holeRadius, distanceFromTop) => {
  const shape = new THREE.Shape();
  const holePath = new THREE.Path();
  const thickness = 0.01;
  const positionY = height / 2 - (radial ? holeRadius : holeHeight / 2) - distanceFromTop;

  if (radial) {
    shape.absarc(0, positionY, holeRadius, 0, Math.PI * 2, 0, false);
    holePath.absarc(0, positionY, holeRadius - thickness, 0, Math.PI * 2, true);
  } else {
    const ductWidth = holeWidth;
    const ductHeight = holeHeight;

    const offsetWidth = ductWidth / 2;
    const offsetHeight = -height / 2 + ductHeight + distanceFromTop;

    shape
      .moveTo(0 - offsetWidth, 0 - offsetHeight)
      .lineTo(0 - offsetWidth, ductHeight - offsetHeight)
      .lineTo(ductWidth - offsetWidth, ductHeight - offsetHeight)
      .lineTo(ductWidth - offsetWidth, 0 - offsetHeight)
      .lineTo(0 - offsetWidth, 0 - offsetHeight);

    holePath
      .moveTo(thickness - offsetWidth, thickness - offsetHeight)
      .lineTo(thickness - offsetWidth, ductHeight - thickness - offsetHeight)
      .lineTo(ductWidth - offsetWidth - thickness, ductHeight - thickness - offsetHeight)
      .lineTo(ductWidth - offsetWidth - thickness, thickness - offsetHeight)
      .lineTo(thickness - offsetWidth, thickness - offsetHeight);
  }

  shape.holes.push(holePath);

  return shape;
};

export const buildDuctPlateWithHole = (height, depth, radial, holeWidth, holeHeight, holeRadius, distanceFromTop) => {
  const thickness = 0.01;
  const box = new THREE.Mesh(new THREE.BoxGeometry(depth, height, thickness));
  const positionY = height / 2 - (radial ? holeRadius : holeHeight / 2) - distanceFromTop;

  var subtractMesh = null;

  if (radial) {
    subtractMesh = new THREE.Mesh(new THREE.CylinderGeometry(holeRadius, holeRadius, 2, 16, 10));
    subtractMesh.rotation.x = Math.PI / 2;
    subtractMesh.updateMatrix();
  } else {
    subtractMesh = new THREE.Mesh(new THREE.BoxGeometry(holeWidth, holeHeight, 10));
  }

  subtractMesh.position.y = positionY;

  const newMesh = CSG.subtract(box, subtractMesh);
  return newMesh.geometry;
};

const buildCentrifugalShape = (size, outletHeight, extendOutlet = 0) => {
  const shape = new THREE.Shape();

  const h = size;
  const w = size;
  const o = w * 0.125; // ammount off width to allow for outlet frame (12.5%)

  const startingPoint = [w * 0.2, h / 2]; // start slightly to the right (20%)
  const b1 = [0, h / 2, (-w / 2) * 0.875, h / 2, -w / 2, 0]; // some factors applied to tangents (87.5%)
  const b2 = [-w / 2, -h / 2, 0, -h / 2, 0, -h / 2];
  const b3 = [0, -h / 2, (w / 2 - o) * 0.94, -h / 2, w / 2 - o, -outletHeight + h / 2]; // some factors applied to tangents (94%)
  const l1 = [extendOutlet + w / 2, -outletHeight + h / 2];
  const l2 = [extendOutlet + w / 2, h / 2];

  shape.moveTo(l2[0], l2[1]);
  shape.lineTo(startingPoint[0], startingPoint[1]);
  shape.bezierCurveTo(b1[0], b1[1], b1[2], b1[3], b1[4], b1[5]);
  shape.bezierCurveTo(b2[0], b2[1], b2[2], b2[3], b2[4], b2[5]);
  shape.bezierCurveTo(b3[0], b3[1], b3[2], b3[3], b3[4], b3[5]);
  shape.lineTo(l1[0], l1[1]);
  //shape.lineTo(l2[0], l2[1]);

  var holeRadius = size * 0.25;
  var holeYPosition = -(holeRadius / 2);
  var holePath = new THREE.Path();
  holePath.absarc(0, holeYPosition, holeRadius, 0, Math.PI * 2, true);
  shape.holes.push(holePath);

  return shape;
};

export const buildCentrifugal = (size, fanWidth, outletHeight) => {
  const h = size;
  const w = size;
  const o = h * 0.125; // final curve point distance away from outlet (12.5%)

  const shape = buildCentrifugalShape(size, outletHeight);

  let extrudeSettings = {
    depth: fanWidth,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };
  let extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, -fanWidth / 2));

  const innerAmmount = 0.05;
  const innerFactor = 1 - innerAmmount;
  const innerShape = buildCentrifugalShape(size * innerFactor, outletHeight * innerFactor * (innerFactor + innerAmmount / 2), 0.5);
  extrudeSettings = {
    depth: fanWidth * innerFactor,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };

  let innerExtrudeGeometry = new THREE.ExtrudeGeometry(innerShape, extrudeSettings);
  innerExtrudeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, (-fanWidth * innerFactor) / 2));

  let mesh = new THREE.Mesh(extrudeGeometry);
  let subtractMesh = new THREE.Mesh(innerExtrudeGeometry);
  let newMesh = CSG.subtract(mesh, subtractMesh);

  // hole for inlet

  // let cylinder = new THREE.CylinderBufferGeometry(0.12, 0.12, depth, 16);
  // cylinder.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
  // cylinder.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -0.04, 0));
  // subtractMesh = new THREE.Mesh(cylinder);
  // mesh = CSG.subtract(newMesh, subtractMesh);

  var holeRadius = size * 0.25;
  var holeYPosition = -(holeRadius / 2);

  const inletWidth = 0.01;

  let inlet = new THREE.CylinderBufferGeometry(holeRadius, holeRadius * 0.8, inletWidth, 32, undefined, true);
  inlet.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
  inlet.applyMatrix4(new THREE.Matrix4().makeTranslation(0, holeYPosition, fanWidth / 2 - inletWidth / 2));

  let inlet2 = new THREE.CylinderBufferGeometry(holeRadius, holeRadius * 0.8, inletWidth, 32, undefined, true);
  inlet2.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
  inlet2.applyMatrix4(new THREE.Matrix4().makeRotationY(Math.PI));
  inlet2.applyMatrix4(new THREE.Matrix4().makeTranslation(0, holeYPosition, -fanWidth / 2 + inletWidth / 2));

  let impeller = new THREE.CylinderBufferGeometry(holeRadius * 0.8, holeRadius * 0.8, fanWidth - inletWidth * 2, 32, undefined, true);
  impeller.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
  impeller.applyMatrix4(new THREE.Matrix4().makeTranslation(0, holeYPosition, 0));

  let middle = new THREE.CylinderBufferGeometry(holeRadius, holeRadius, 0.005, 32);
  middle.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
  middle.applyMatrix4(new THREE.Matrix4().makeTranslation(0, holeYPosition, 0));

  let shaft = new THREE.CylinderBufferGeometry(0.01, 0.01, fanWidth, 32);
  shaft.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
  shaft.applyMatrix4(new THREE.Matrix4().makeTranslation(0, holeYPosition, 0));

  const shaft_bearing_width = 0.05;
  let shaft_bearing = new THREE.CylinderBufferGeometry(holeRadius * 0.4, holeRadius * 0.4, shaft_bearing_width, 32);
  shaft_bearing.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
  shaft_bearing.applyMatrix4(new THREE.Matrix4().makeTranslation(0, holeYPosition, 0));

  return [newMesh.geometry, inlet, inlet2, middle, impeller, shaft_bearing, shaft];
};

export const buildExtrudedBoxGeometry = (width, height, depth) => {
  const shape = new THREE.Shape();
  let extrudeSettings = {
    depth: width,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };

  const offsetWidth = depth / 2;
  const offsetHeight = height / 2;

  shape
    .moveTo(0 - offsetWidth, 0 - offsetHeight)
    .lineTo(0 - offsetWidth, height - offsetHeight)
    .lineTo(depth - offsetWidth, height - offsetHeight)
    .lineTo(depth - offsetWidth, 0 - offsetHeight)
    .lineTo(0 - offsetWidth, 0 - offsetHeight);

  let extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeRotationY(Math.PI / 2));
  extrudeGeometry.applyMatrix4(new THREE.Matrix4().makeTranslation(-width / 2, 0, 0));

  return extrudeGeometry;
};

export function buildFrameGeometry(length, width, height, hasLeftFrame, hasRightFrame, hasFloor, hasLeftWall, hasRightWall, hasFrame, frameThickness) {
  const geos = [];

  if (hasFrame === false) return null;

  const frame_length = frameThickness || DEFAULT_EMPTY_THICKNESS;

  const right_offset = length / 2 + frame_length / 2;
  const left_offset = -1 * right_offset;

  const front_offset = width / 2 + frame_length / 2;
  const back_offset = -1 * front_offset;

  const top_offset = height / 2 + frame_length / 2;
  const bottom_offset = -1 * top_offset;

  const sizeFactor = 0.999;

  if (hasLeftFrame) {
    const back_left = new THREE.BoxGeometry(frame_length, height, frame_length); // new THREE.BoxGeometry(frame_length, height, frame_length);
    back_left.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, 0, back_offset));
    geos.push(back_left);
  }
  if (hasRightFrame) {
    const back_right = new THREE.BoxGeometry(frame_length, height, frame_length);
    back_right.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, 0, back_offset));
    geos.push(back_right);
  }
  const back_top = new THREE.BoxGeometry(length * sizeFactor, frame_length, frame_length);
  back_top.applyMatrix4(new THREE.Matrix4().makeTranslation(0, top_offset, back_offset));
  geos.push(back_top);

  const back_bottom = new THREE.BoxGeometry(length * sizeFactor, frame_length, frame_length);
  back_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(0, bottom_offset, back_offset));
  geos.push(back_bottom);

  if (hasLeftFrame) {
    const front_left = new THREE.BoxGeometry(frame_length, height, frame_length);
    front_left.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, 0, front_offset));
    geos.push(front_left);
  }
  if (hasRightFrame) {
    const front_right = new THREE.BoxGeometry(frame_length, height, frame_length);
    front_right.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, 0, front_offset));
    geos.push(front_right);
  }
  const front_top = new THREE.BoxGeometry(length * sizeFactor, frame_length, frame_length);
  front_top.applyMatrix4(new THREE.Matrix4().makeTranslation(0, top_offset, front_offset));
  geos.push(front_top);

  const front_bottom = new THREE.BoxGeometry(length * sizeFactor, frame_length, frame_length);
  front_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(0, bottom_offset, front_offset));
  geos.push(front_bottom);

  if (hasLeftFrame) {
    const left_top = new THREE.BoxGeometry(frame_length, frame_length, width * sizeFactor);
    left_top.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, top_offset, 0));
    geos.push(left_top);

    const left_bottom = new THREE.BoxGeometry(frame_length, frame_length, width * sizeFactor);
    left_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, bottom_offset, 0));
    geos.push(left_bottom);
  }

  if (hasRightFrame) {
    const right_top = new THREE.BoxGeometry(frame_length, frame_length, width * sizeFactor);
    right_top.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, top_offset, 0));
    geos.push(right_top);

    const right_bottom = new THREE.BoxGeometry(frame_length, frame_length, width * sizeFactor);
    right_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, bottom_offset, 0));
    geos.push(right_bottom);
  }

  const panel_thickness = fromMM(5);
  const panel_length = hasLeftFrame && hasRightFrame ? length : hasLeftFrame || hasRightFrame ? length : length;
  const panel_height = height;
  const panel_length_offset = 0; //hasLeftFrame && hasRightFrame ? 0 : hasLeftFrame ? frameThickness / 2 : hasRightFrame ? -frameThickness / 2 : 0;
  const panel_height_offset = panel_thickness / 2 + height / 2; // - frame_length;

  if (hasFloor) {
    const floor = new THREE.BoxGeometry(panel_length * sizeFactor, panel_thickness, width * sizeFactor);
    floor.applyMatrix4(new THREE.Matrix4().makeTranslation(panel_length_offset, panel_height_offset * -1, 0));
    geos.push(floor);
  }

  // if (hasCeiling) {
  //   const ceiling = new THREE.BoxGeometry(panel_length, panel_thickness, width);
  //   ceiling.applyMatrix4(new THREE.Matrix4().makeTranslation(panel_length_offset, panel_height_offset - panel_thickness + frame_length, 0));
  //   geos.push(ceiling);
  // }

  // if (hasLeftWall) {
  //   const wall = new THREE.BoxGeometry(panel_thickness, panel_height, width);
  //   wall.applyMatrix4(new THREE.Matrix4().makeTranslation(-(frame_length + (length - panel_thickness) / 2), 0, 0));
  //   geos.push(wall);
  // }

  // if (hasRightWall) {
  //   const wall = new THREE.BoxGeometry(panel_thickness, panel_height, width);
  //   wall.applyMatrix4(new THREE.Matrix4().makeTranslation(frame_length + (length - panel_thickness) / 2, 0, 0));
  //   geos.push(wall);
  // }
  return mergeBufferGeometries(geos);
}

export function buildCornersGeometry(length, width, height, hasLeftFrame, hasRightFrame, frameThickness) {
  const geos = [];

  if (hasLeftFrame == false && hasRightFrame == false) return null;

  const frame_length = (frameThickness || DEFAULT_EMPTY_THICKNESS) * 1;

  const right_offset = length / 2 + frame_length / 2;
  const left_offset = -1 * right_offset;

  const front_offset = width / 2 + frame_length / 2;
  const back_offset = -1 * front_offset;

  const top_offset = height / 2 + frame_length / 2;
  const bottom_offset = -1 * top_offset;

  if (hasLeftFrame) {
    // Left
    const back_top = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    back_top.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, top_offset, back_offset));
    geos.push(back_top);

    const back_bottom = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    back_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, bottom_offset, back_offset));
    geos.push(back_bottom);

    const front_top = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    front_top.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, top_offset, front_offset));
    geos.push(front_top);

    const front_bottom = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    front_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(left_offset, bottom_offset, front_offset));
    geos.push(front_bottom);
  }

  if (hasRightFrame) {
    // Right
    const left_top = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    left_top.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, top_offset, back_offset));
    geos.push(left_top);

    const left_bottom = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    left_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, bottom_offset, back_offset));
    geos.push(left_bottom);

    const right_top = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    right_top.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, top_offset, front_offset));
    geos.push(right_top);

    const right_bottom = new THREE.BoxGeometry(frame_length, frame_length, frame_length);
    right_bottom.applyMatrix4(new THREE.Matrix4().makeTranslation(right_offset, bottom_offset, front_offset));
    geos.push(right_bottom);
  }

  return mergeBufferGeometries(geos);
}

export function buildBagFilter(height, length, thickness, ammount) {
  const geometry = new THREE.BufferGeometry();

  const reducedEnd = (height / 2) * 0.6;
  const reducedStart = (height / 2) * 0.9;
  const end = length / 2;
  const start = -length / 2;
  const height_start = height / 2;
  const height_end = -height / 2;

  const vertices = new Float32Array([
    end,
    0,
    reducedEnd,
    //
    start,
    thickness,
    height_start,
    //
    start,
    0,
    reducedStart,
    //
    start,
    thickness,
    height_end,
    //
    end,
    0,
    -reducedEnd,
    //
    start,
    0,
    -reducedStart,
    //
    end,
    0,
    -reducedEnd,
    //
    end,
    0,
    reducedEnd,
    //
    end,
    0,
    reducedEnd,
    //
    end,
    0,
    -reducedEnd,
    //
    end,
    0,
    reducedEnd,
    //
    start,
    thickness,
    height_end,
    //
    start,
    thickness,
    height_start,
    //
    end,
    0,
    -reducedEnd,
    //
    start,
    thickness,
    height_start,
    //
    start,
    0,
    -reducedStart,
    //
    start,
    0,
    reducedStart,
    //
    start,
    thickness,
    height_end,
  ]);

  const normals = new Float32Array([
    0, -0.904006, 0.427466, 0, -0.904006, 0.427466, 0, -0.904006, 0.427466, 0, -0.908168, -0.418585, 0, -0.908168, -0.418585, 0.00420923, -0.908168, -0.418585, 0, 0, 1, 0, 0, 1, 0,
    0, 1, 0, 0, 1, 0.10544, 1, 0, 0.10544, 1, 0, 0.10544, 1, 0, 0.10544, 1, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0,
  ]);

  const indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 8, 9, 6, 10, 11, 12, 11, 10, 13, 14, 15, 16, 14, 17, 15];

  geometry.setIndex(indices);
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setAttribute("normal", new THREE.Float32BufferAttribute(normals, 3));
  geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));

  let geometry_clone = geometry.clone();
  geometry_clone.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI));
  geometry_clone.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, 0));

  let complete_geometry = mergeBufferGeometries([geometry, geometry_clone]);

  // // Make the filters open
  // // Not sure if we're bothered about this or not
  // let mesh = new THREE.Mesh(complete_geometry);
  // let mesh_smaller = new THREE.Mesh(complete_geometry.clone());
  // mesh_smaller.scale.set(0.8, 0.95, 0.8);
  // mesh_smaller.position.set(-0.05, 0, 0);
  // let result_mesh = CSG.subtract(mesh, mesh_smaller);
  // complete_geometry = result_mesh.geometry;
  // //

  let geos = [complete_geometry];

  let offset = thickness * 2;
  for (let i = 1; i < ammount; i++) {
    let geometry_clone = complete_geometry.clone();
    geometry_clone.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, offset));
    geos.push(geometry_clone);
    offset += thickness * 2;
  }

  complete_geometry = mergeBufferGeometries(geos);
  complete_geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, thickness + -ammount * thickness));

  return complete_geometry;
}

export function buildPanel(length, height, width) {
  const geometry = new THREE.BoxGeometry(length, height, width);
  return geometry;
}
