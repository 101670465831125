import { useRef, useMemo, useEffect, useState } from "react";
import * as THREE from "three";
import { COMPONENT_ALIGNMENT, COMPONENT_TYPES } from "../Constants";
import { Edges } from "@react-three/drei";
import HeatExchanger from "./HeatExchanger";
import { align, calculateAttached, fromMM, getDefaultFrameSize, toMM } from "../Utils/frameUtils";
import Filter from "./Filter";
import Coil from "./Coil";
import Damper from "./Damper";
import Duct from "./Duct";
import Fan from "./Fan";
import Connection from "./Connection";
import { useStore } from "../Store/zustandStore";
import { buildCornersGeometry, buildFrameGeometry, buildPanel } from "../Utils/geometryUtils";
import UVLights from "./UVLights";
import CarbonFilter from "./CarbonFilter";
import BagFilter from "./BagFilter";
import { useSpring, animated, config } from "@react-spring/three";
import FloatingButton from "./FloatingButton";
import CircularMenuButton from "./CircularMenuButton";
import DoorProperties from "./Properties/DoorProperties";
import Door from "./Door";
import Panel from "./Panel";
import ControlBox from "./ControlBox";
import Ozone from "./Ozone";
import OdourNeutraliser from "./OdourNeutraliser";

export default function Frame({ data, children, isDragging, selected, setPickup, handleSelection, updateUnit, frames }) {
  const {
    position,
    connections,
    placing,
    id,
    sectionId,
    component,
    hasLeftFrame,
    hasRightFrame,
    hasFrame,
    hasFloor,
    hasCeiling,
    hasLeftWall,
    hasRightWall,
    hasFlange,
    hasControlBox,
    flangeAlignment,
    visible,
  } = data;

  const [frameThickness, showPanels, panelOpacity, doorsOpen] = useStore((state) => [fromMM(state.frameThickness), state.showPanels, state.panelOpacity, state.doorsOpen]);

  const length = fromMM(data.length);
  const height = fromMM(data.height);
  const width = fromMM(data.width);

  const meshRef = useRef();

  const getColour = (defaultColour) => {
    return selected ? "Orange" : sectionId ? "#8F6F7F" : defaultColour;
  };

  const renderComponent = () => {
    switch (component) {
      case COMPONENT_TYPES.COIL:
        return <Coil frame={data} length={0.1} height={height} />;
      case COMPONENT_TYPES.FAN:
        return <Fan selected={selected} frame={data} />;
      case COMPONENT_TYPES.DAMPER:
        return <Damper scale={[0.45, 0.45, 0.45]} frame={data} />;
      case COMPONENT_TYPES.FILTER:
        return <Filter frame={data} length={0.05} height={height} />;
      case COMPONENT_TYPES.DUCT:
        return <Duct frame={data} anchorH={1} height={height} getColour={getColour} />;
      case COMPONENT_TYPES.PLATE_HEAT_EXCHANGER:
      case COMPONENT_TYPES.ROTARY_HEAT_EXCHANGER:
        return <HeatExchanger frame={data} />;
      case COMPONENT_TYPES.UV_LIGHTS:
        return <UVLights frame={data} getColour={getColour} />;
      case COMPONENT_TYPES.CARBON_FILTER:
        return <CarbonFilter frame={data} getColour={getColour} />;
      case COMPONENT_TYPES.BAG_FILTER:
        return <BagFilter frame={data} getColour={getColour} />;
      case COMPONENT_TYPES.OZONE:
        return <Ozone frame={data} getColour={getColour} />;
      case COMPONENT_TYPES.ODOUR_NEUTRALISER:
        return <OdourNeutraliser frame={data} getColour={getColour} />;
    }
  };

  useEffect(() => {
    if (placing) setPickup(meshRef.current);
  }, [placing]);

  const geom = useMemo(() => {
    return buildFrameGeometry(length, width, height, hasLeftFrame, hasRightFrame, hasFloor, hasLeftWall, hasRightWall, hasFrame, frameThickness);
  }, [length, width, height, hasLeftFrame, hasRightFrame, hasFloor, hasLeftWall, hasRightWall, hasFrame, frameThickness]);

  const corners = useMemo(() => {
    return buildCornersGeometry(length, width, height, hasLeftFrame, hasRightFrame, frameThickness);
  }, [length, width, height, hasLeftFrame, hasRightFrame, frameThickness]);

  const addFrameToConnection = (connection, connectionPosition, componentType) => {
    const newLength = getDefaultFrameSize(componentType);

    const newPosition = [
      connectionPosition[0] + position[0] + ((connection.side == COMPONENT_ALIGNMENT.LEFT ? -1 : 1) * (fromMM(newLength) + frameThickness * 2)) / 2,
      connectionPosition[1] + position[1],
      connectionPosition[2] + position[2],
    ];

    const newFrame = {
      id: THREE.MathUtils.generateUUID(),
      position: newPosition,
      length: newLength,
      height: data.height / connection.position.length,
      width: data.width,
      connections: [
        {
          id: THREE.MathUtils.generateUUID(),
          side: COMPONENT_ALIGNMENT.LEFT,
          position: [1],
          attachedTo: connection.side == COMPONENT_ALIGNMENT.RIGHT ? connection.id : null,
          direction: connection.direction,
        },
        {
          id: THREE.MathUtils.generateUUID(),
          side: COMPONENT_ALIGNMENT.RIGHT,
          position: [1],
          attachedTo: connection.side == COMPONENT_ALIGNMENT.LEFT ? connection.id : null,
          direction: connection.direction,
        },
      ],
      hasLeftFrame: true,
      hasRightFrame: true,
      hasFrame: true,
      hasFloor: true,
      hasCeiling: true,
      hasLeftWall: false,
      hasRightWall: false,
      component: componentType,
    };

    const newFrames = frames
      .map((f) => {
        if (f.id === id) {
          return {
            ...f,
            connections: f.connections.map((c) => {
              if (c.id === connection.id) {
                return {
                  ...c,
                  attachedTo: newFrame.connections[connection.side == COMPONENT_ALIGNMENT.LEFT ? 0 : 1].id,
                };
              } else {
                return c;
              }
            }),
          };
        } else {
          return f;
        }
      })
      .concat(newFrame);

    updateUnit("FRAMES", -1, calculateAttached(newFrames, toMM(frameThickness)));
  };

  return (
    <mesh
      ref={meshRef}
      uuid={id}
      position={position}
      castShadow
      receiveShadow
      geometry={geom}
      onPointerDown={(e) => {
        if (e.button !== 0) return;
        handleSelection(e, data);
      }}
      userData={{ type: "frame", connections: connections }}
      visible={visible == undefined || visible}
      selected={selected}
      //rotation-x={-Math.PI / 2}
    >
      <meshStandardMaterial side={2} color={getColour("Grey")} />
      <Edges scale={1} renderOrder={1000}>
        <meshBasicMaterial transparent color="Grey" widthTest={true} />
      </Edges>
      {connections.map((c, i) => (
        <Connection
          key={c.id}
          connection={c}
          frameLength={length}
          frameHeight={height}
          frameWidth={width}
          hasFrame={(c.side == COMPONENT_ALIGNMENT.LEFT && hasLeftFrame) || (c.side == COMPONENT_ALIGNMENT.RIGHT && hasRightFrame)}
          visible={
            !c.attachedTo &&
            (!hasFlange || c.side !== flangeAlignment) &&
            (!hasLeftWall || c.side != COMPONENT_ALIGNMENT.LEFT) &&
            (!hasRightWall || c.side != COMPONENT_ALIGNMENT.RIGHT)
          }
          isDragging={isDragging}
          attached={c.attachedTo}
          addFrame={addFrameToConnection}
        ></Connection>
      ))}
      {children}
      {renderComponent()}
      {(hasLeftFrame || hasRightFrame) && (
        <mesh geometry={corners} receiveShadow>
          <meshStandardMaterial side={2} color={"DimGrey"} />
        </mesh>
      )}

      {showPanels && (
        <>
          {hasCeiling && (
            <Panel
              length={length * 0.999}
              height={width * 0.999}
              rotation={[Math.PI / 2, 0, 0]}
              thickness={fromMM(10)}
              position={[0, height / 2 + frameThickness - fromMM(10) / 2, 0]}
              opacity={panelOpacity}
              selected={selected}
              getColour={getColour}
            />
          )}

          {hasLeftWall && (
            <Panel
              length={width * 0.999}
              height={height * 0.999}
              rotation={[0, Math.PI / 2, 0]}
              thickness={fromMM(10)}
              position={[-length / 2 - frameThickness + fromMM(10) / 2, 0, 0]}
              opacity={panelOpacity}
              selected={selected}
              getColour={getColour}
            />
          )}

          {hasRightWall && (
            <Panel
              length={width * 0.999}
              height={height * 0.999}
              rotation={[0, Math.PI / 2, 0]}
              thickness={fromMM(10)}
              position={[length / 2 + frameThickness - fromMM(10) / 2, 0, 0]}
              opacity={panelOpacity}
              selected={selected}
              getColour={getColour}
            />
          )}

          <Panel
            length={length * 0.999}
            height={height * 0.999}
            thickness={frameThickness}
            position={[0, 0, -width / 2 - frameThickness / 2]}
            opacity={panelOpacity}
            doorsOpen={doorsOpen}
            selected={selected}
            hasMenu={false}
            getColour={getColour}
          />

          <Panel
            length={length * 0.999}
            height={height * 0.999}
            thickness={frameThickness}
            position={[0, 0, width / 2 + frameThickness / 2]}
            opacity={panelOpacity}
            doorsOpen={doorsOpen}
            selected={selected}
            hasMenu={false}
            getColour={getColour}
            door={data.door}
          />
        </>
      )}

      {hasControlBox && <ControlBox frame={data} position={[0, 0, width / 2 + frameThickness]} />}
    </mesh>
  );
}
