import create from "zustand";
import { DEFAULT_EMPTY_THICKNESS, TOOL_MODE } from "../Constants";

export const useStore = create((set) => ({
  selectedConnectionMenu: null,
  setSelectedConnectionMenu: (connectionId) => set(() => ({ selectedConnectionMenu: connectionId })),

  toolMode: TOOL_MODE.SELECTION,
  setToolMode: (mode) => set(() => ({ toolMode: mode })),

  selectedUUID: null,
  setSelectedUUID: (ids) => set(() => ({ selectedUUID: ids })),

  showDimensions: true,
  setShowDimensions: (value) => set(() => ({ showDimensions: value })),

  frameThickness: DEFAULT_EMPTY_THICKNESS,
  setFrameThickness: (value) => set(() => ({ frameThickness: value })),

  showPanels: false,
  setShowPanels: (value) => set(() => ({ showPanels: value })),

  panelOpacity: 0.5,
  setPanelOpacity: (value) => set(() => ({ panelOpacity: value })),

  doorsOpen: false,
  setDoorsOpen: (value) => set(() => ({ doorsOpen: value })),

  requestDrawings: false,
  setRequestDrawings: (value) => set(() => ({ requestDrawings: value })),

  drawings: [],
  setDrawings: (value) => set(() => ({ drawings: value })),

  drawingTolerance: 50,
  setDrawingTolerance: (value) => set(() => ({ drawingTolerance: value })),
}));
