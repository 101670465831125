import { EventDispatcher, Matrix4, ObjectSpaceNormalMap, Plane, Raycaster, Vector2, Vector3, Box3 } from "three";

const _plane = new Plane();
const _raycaster = new Raycaster();

const _pointer = new Vector2();
const _offset = new Vector3();
const _intersection = new Vector3();
const _worldPosition = new Vector3();
const _inverseMatrix = new Matrix4();
const _aabb = new Box3();
const _boxSize = new Vector3();
const _boxPos = new Vector3();

class DragControls extends EventDispatcher {
  constructor(_objects, _camera, _domElement) {
    super();

    _domElement.style.touchAction = "none"; // disable touch scroll

    let _selected = null,
      _hovered = null;

    const _intersections = [];

    //

    const scope = this;

    function activate() {
      _domElement.addEventListener("pointermove", onPointerMove);
      _domElement.addEventListener("pointerdown", onPointerDown);
      _domElement.addEventListener("pointerup", onPointerCancel);
      _domElement.addEventListener("pointerleave", onPointerCancel);
    }

    function deactivate() {
      _domElement.removeEventListener("pointermove", onPointerMove);
      _domElement.removeEventListener("pointerdown", onPointerDown);
      _domElement.removeEventListener("pointerup", onPointerCancel);
      _domElement.removeEventListener("pointerleave", onPointerCancel);

      //_domElement.style.cursor = "";
    }

    function dispose() {
      deactivate();
      console.log("dispose");
    }

    function getObjects() {
      return _objects;
    }

    function getRaycaster() {
      return _raycaster;
    }

    function onPointerMove(event) {
      if (scope.enabled === false || !_objects) return;

      updatePointer(event);

      _raycaster.setFromCamera(_pointer, _camera);

      if (_selected) {
        if (_raycaster.ray.intersectPlane(_plane, _intersection)) {
          _selected.position.copy(_intersection.sub(_offset).applyMatrix4(_inverseMatrix));

          _aabb.setFromObject(_selected);
          const height = _aabb.getSize(_boxSize);
          const pos = _aabb.getCenter(_boxPos);

          if (pos.y < height.y / 2) {
            _selected.position.y = -1 * (pos.clone().applyMatrix4(_selected.matrixWorld.clone().invert()).y - height.y / 2);
          }
        }

        scope.dispatchEvent({ type: "drag", object: _selected, ray: _raycaster.ray });

        return;
      }
    }

    function onPointerDown(event) {
      if (scope.enabled === false || event.button !== 0) return;

      updatePointer(event);

      _intersections.length = 0;

      _raycaster.setFromCamera(_pointer, _camera);
      _raycaster.intersectObjects(_objects, true, _intersections);

      if (_intersections.length > 0) {
        _selected =
          scope.transformGroup === true ? _objects.find((o) => o.type == "Group") : _intersections.find((i) => i.object.userData?.type == "frame" && i.object.selected)?.object;

        if (_selected) {
          _plane.setFromNormalAndCoplanarPoint(_camera.getWorldDirection(_plane.normal), _worldPosition.setFromMatrixPosition(_selected.matrixWorld));

          if (_raycaster.ray.intersectPlane(_plane, _intersection)) {
            _inverseMatrix.copy(_selected.parent.matrixWorld).invert();
            _offset.copy(_intersection).sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
          }

          _aabb.setFromObject(_selected);

          //_domElement.style.cursor = "move";

          scope.dispatchEvent({ event: event, type: "dragstart", object: _selected, ray: _raycaster.ray });
        }
      }
    }

    function onPointerCancel(event) {
      if (scope.enabled === false) return;

      if (_selected) {
        updatePointer(event);

        _intersections.length = 0;

        _raycaster.setFromCamera(_pointer, _camera);

        scope.dispatchEvent({ type: "dragend", object: _selected, ray: _raycaster.ray });

        _selected = null;
      }

      //_domElement.style.cursor = _hovered ? "pointer" : "auto";
    }

    function updatePointer(event) {
      const rect = _domElement.getBoundingClientRect();

      _pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      _pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
    }

    function pickup(object) {
      _selected = object;
      console.log("pickup");

      _plane.setFromNormalAndCoplanarPoint(_camera.getWorldDirection(_plane.normal), _worldPosition.setFromMatrixPosition(_selected.matrixWorld));

      if (_raycaster.ray.intersectPlane(_plane, _intersection)) {
        _inverseMatrix.copy(_selected.parent.matrixWorld).invert();
        _offset.copy(_intersection).sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
      }

      //_domElement.style.cursor = "move";

      scope.dispatchEvent({ type: "dragstart", object: _selected });
    }

    activate();

    // API

    this.enabled = false;
    this.transformGroup = false;

    this.activate = activate;
    this.deactivate = deactivate;
    this.dispose = dispose;
    this.getObjects = getObjects;
    this.getRaycaster = getRaycaster;
    this.pickup = pickup;
  }
}

export { DragControls };
