import { COMPONENT_ALIGNMENT } from "../../Constants";

export default {
  visible: false,
  length: 250,
  alignment: {
    value: COMPONENT_ALIGNMENT.LEFT,
    options: [
      { text: "Left", value: COMPONENT_ALIGNMENT.LEFT },
      { text: "Right", value: COMPONENT_ALIGNMENT.RIGHT },
    ],
  },
  hinge: {
    value: COMPONENT_ALIGNMENT.LEFT,
    options: [
      { text: "Left", value: COMPONENT_ALIGNMENT.LEFT },
      { text: "Right", value: COMPONENT_ALIGNMENT.RIGHT },
    ],
  },
};
