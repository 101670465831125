import { useMemo } from "react";
import * as THREE from "three";
import { Edges, useGLTF } from "@react-three/drei";
import { buildHollowFrame, buildPipe } from "../Utils/geometryUtils";
import CoilProperties from "./Properties/CoilProperties";
import { fromMM, calculationComponentPosition } from "../Utils/frameUtils";
import { COMPONENT_SIDE } from "../Constants";

export default function Coil(props) {
  const { frame, height, length } = props;
  const { coil } = frame;
  const width = fromMM(frame.width) * 0.8;
  const coilFrameThickness = 0.04;

  const pipeRadius = fromMM(coil?.pipeRadius || CoilProperties.pipeRadius);
  const pipeThickness = fromMM(coil?.pipeThickness || CoilProperties.pipeThickness);
  const pipeLengthInlet = fromMM(coil?.pipeLengthInlet || CoilProperties.pipeLengthInlet);
  const pipeLengthOutlet = fromMM(coil?.pipeLengthOutlet || CoilProperties.pipeLengthOutlet);

  const pipeInletPositionFromTop = fromMM(coil?.pipeInletPositionFromTop || CoilProperties.pipeInletPositionFromTop);
  const pipeOutletPositionFromTop = fromMM(coil?.pipeOutletPositionFromTop || CoilProperties.pipeOutletPositionFromTop);

  const pipeInletPositionFromLeft = fromMM(coil?.pipeInletPositionFromLeft || CoilProperties.pipeInletPositionFromLeft);
  const pipeOutletPositionFromLeft = fromMM(coil?.pipeOutletPositionFromLeft || CoilProperties.pipeOutletPositionFromLeft);

  const pipeLengthSideInlet = height - 0.01;
  const pipeLengthSideOutlet = height - 0.01;

  const inlet_pipe_geometry = useMemo(() => {
    return buildPipe(pipeRadius, pipeThickness, pipeLengthInlet);
  }, [pipeRadius, pipeThickness, pipeLengthInlet]);

  const inlet_side_pipe_geometry = useMemo(() => {
    return buildPipe(pipeRadius, pipeThickness, pipeLengthSideInlet);
  }, [pipeRadius, pipeThickness, pipeLengthSideInlet]);

  const outlet_pipe_geometry = useMemo(() => {
    return buildPipe(pipeRadius, pipeThickness, pipeLengthOutlet);
  }, [pipeRadius, pipeThickness, pipeLengthOutlet]);

  const outlet_side_pipe_geometry = useMemo(() => {
    return buildPipe(pipeRadius, pipeThickness, pipeLengthSideOutlet);
  }, [pipeRadius, pipeThickness, pipeLengthSideOutlet]);

  const coil_frame = useMemo(() => {
    return buildHollowFrame(length, height, width, coilFrameThickness);
  }, [length, height, width, coilFrameThickness]);

  return (
    <group dispose={null} position={calculationComponentPosition(length, frame)}>
      <mesh>
        <boxGeometry args={[length * 0.75, height - coilFrameThickness, width - coilFrameThickness * 2]} />
        <meshStandardMaterial color={"Peru"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh geometry={coil_frame}>
        <meshStandardMaterial color={"Grey"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>

      <mesh
        position={[
          -length / 2 + pipeInletPositionFromLeft,
          pipeLengthSideInlet / 2,
          ((coil?.inletPipeSide || CoilProperties.inletPipeSide) === COMPONENT_SIDE.LEFT ? width : -width) / 2,
        ]}
        geometry={inlet_side_pipe_geometry}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color={"Peru"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>

      <mesh
        position={[
          -length / 2 + pipeOutletPositionFromLeft,
          pipeLengthSideOutlet / 2,
          ((coil?.outletPipeSide || CoilProperties.outletPipeSide) === COMPONENT_SIDE.LEFT ? width : -width) / 2,
        ]}
        geometry={outlet_side_pipe_geometry}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color={"Peru"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>

      <mesh
        position={[
          -length / 2 + pipeInletPositionFromLeft,
          height / 2 - pipeInletPositionFromTop,
          (coil?.inletPipeSide || CoilProperties.inletPipeSide) === COMPONENT_SIDE.LEFT ? width / 2 : -width / 2 - pipeLengthInlet,
        ]}
        geometry={inlet_pipe_geometry}
      >
        <meshStandardMaterial color={"Peru"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh
        position={[
          -length / 2 + pipeOutletPositionFromLeft,
          height / 2 - pipeOutletPositionFromTop,
          (coil?.outletPipeSide || CoilProperties.outletPipeSide) === COMPONENT_SIDE.LEFT ? width / 2 : -width / 2 - pipeLengthOutlet,
        ]}
        geometry={outlet_pipe_geometry}
      >
        <meshStandardMaterial color={"Peru"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
