import { COMPONENT_ALIGNMENT, DEFAULT_EMPTY_THICKNESS, DEFAULT_EMPTY_LENGTH, COMPONENT_TYPES } from "../Constants";
import * as THREE from "three";

export const toMM = (v) => v / 0.001;
export const fromMM = (v) => v * 0.001;

export const calculationComponentPosition = (component_size, frame) => {
  const length = fromMM(frame.length);
  return [align(0, length, component_size, frame.componentAlignment), 0, 0];
};

export const align = (position, parentSize, childSize, alignment) => {
  switch (alignment) {
    case COMPONENT_ALIGNMENT.LEFT:
      return position - parentSize / 2 + childSize / 2;
    case COMPONENT_ALIGNMENT.RIGHT:
      return position + parentSize / 2 - childSize / 2;
    default:
      return 0;
  }
};

export const getFramesFromScene = (scene) => {
  return scene.children.filter((c) => c.userData?.type == "frame");
};

export const calculateAttached = (frames, frameThickness, objects) => {
  let connections = [];

  let flattenedObjects = objects?.filter((o) => o instanceof THREE.Mesh);
  const transformGroup = objects?.find((o) => o instanceof THREE.Group);
  if (transformGroup) {
    flattenedObjects = flattenedObjects.concat(transformGroup.children);
  }

  let newFrames = frames.map((f) => {
    const object = flattenedObjects?.find((o) => o.uuid === f.id);
    if (object) {
      return {
        ...f,
        position: [object.position.x, object.position.y, object.position.z],
      };
    } else {
      return f;
    }
  });

  newFrames.forEach((f) => {
    f.connections.forEach((c) => {
      const c_pos = c.position.length == 1 ? 0 : c.position.indexOf(1) * (fromMM(f.height) / c.position.length) - fromMM(f.height) / c.position.length / 2;
      const p = [
        round(
          c.side === COMPONENT_ALIGNMENT.RIGHT
            ? f.position[0] + fromMM(f.length) / 2 + (f.hasRightFrame ? fromMM(frameThickness) : 0)
            : f.position[0] - (fromMM(f.length) / 2 + (f.hasLeftFrame ? fromMM(frameThickness) : 0))
        ),
        round(c_pos + f.position[1]),
        round(f.position[2]),
      ];

      newFrames.forEach((of) => {
        if (f.id !== of.id && !of?.delete) {
          of.connections.forEach((oc) => {
            const oc_pos = oc.position.length == 1 ? 0 : oc.position.indexOf(1) * (fromMM(of.height) / oc.position.length) - fromMM(of.height) / oc.position.length / 2;
            const op = [
              round(
                oc.side === COMPONENT_ALIGNMENT.RIGHT
                  ? of.position[0] + fromMM(of.length) / 2 + (of.hasRightFrame ? fromMM(frameThickness) : 0)
                  : of.position[0] - (fromMM(of.length) / 2 + (of.hasLeftFrame ? fromMM(frameThickness) : 0))
              ),
              round(oc_pos + of.position[1]),
              round(of.position[2]),
            ];

            if (p[0] == op[0] && p[1] == op[1] && p[2] == op[2]) {
              connections.push({ frame: f.id, from: c.id, to: oc.id });
            }
          });
        }
      });
    });
  });

  newFrames = newFrames.map((f) => {
    const connectionLink = connections.filter((c) => c.frame === f.id);
    if (connectionLink?.length > 0) {
      return {
        ...f,
        connections: f.connections.map((c) => {
          const cl = connectionLink.find((cl) => cl.from === c.id);
          return {
            ...c,
            attachedTo: cl ? cl.to : null,
            direction: c.lockedDirection ? c.direction : null,
          };
        }),
      };
    } else {
      return {
        ...f,
        connections: f.connections.map((c) => {
          return {
            ...c,
            attachedTo: null,
            direction: c.lockedDirection ? c.direction : null,
          };
        }),
      };
    }
  });

  const framesWithLockedDirectionConnections = newFrames.filter((f) => f.connections.filter((c) => c.lockedDirection).length > 0);

  function setDirection(connection) {
    const attachedFrame = findFrameWithConnectionId(newFrames, connection.attachedTo);

    attachedFrame.connections.forEach((attachedConnection) => {
      attachedConnection.direction = connection.direction;

      if (attachedConnection.id !== connection.attachedTo && attachedConnection.attachedTo) {
        setDirection(attachedConnection);
      }
    });
  }

  framesWithLockedDirectionConnections.forEach((frame) => {
    frame.connections
      .filter((c) => c.lockedDirection && c.attachedTo)
      .forEach((connection) => {
        setDirection(connection);
      });
  });

  return newFrames;
};

export const findFrameWithConnectionId = (frames, id) => {
  for (let f = 0; f < frames.length; f++) {
    const frame = frames[f];
    const connection = frame.connections.find((c) => c.id == id);
    if (connection) return frame;
  }
};

export const findConnection = (frames, id) => {
  for (let f = 0; f < frames.length; f++) {
    const frame = frames[f];
    const connection = frame.connections.find((c) => c.id == id);
    if (connection) return connection;
  }
};

export const adjustPosition = (frame, newFrames, direction, difference) => {
  frame.position = [frame.position[0] + (direction == COMPONENT_ALIGNMENT.LEFT ? -difference : difference), frame.position[1], frame.position[2]];

  frame.connections
    .filter((c) => c.attachedTo && c.side === direction)
    .forEach((c) => {
      const attachedFrame = newFrames.find((f) => f.connections.find((fc) => fc.id === c.attachedTo) != null);
      adjustPosition(attachedFrame, newFrames, direction, difference);
    });
};

export function round(num) {
  return Math.round((num + Number.EPSILON) * 1000) / 1000;
}

export function getDefaultFrameSize(componentType) {
  switch (componentType) {
    case COMPONENT_TYPES.FAN:
      return DEFAULT_EMPTY_LENGTH * 1.5;
    case COMPONENT_TYPES.FILTER:
      return DEFAULT_EMPTY_LENGTH / 2;
    default:
      return DEFAULT_EMPTY_LENGTH;
  }
}

export function toggleHasFrame(hasFrame, side, frames, selectedIds, frameThicknessMM) {
  const difference = fromMM(frameThicknessMM) * (hasFrame ? 1 : -1);
  const newFrames = [...frames];

  selectedIds.forEach((id) => {
    const frame = newFrames.find((f) => f.id === id);
    frame.position = [frame.position[0], frame.position[1], frame.position[2]];
    if (side === COMPONENT_ALIGNMENT.LEFT) {
      frame.hasLeftFrame = hasFrame;
    }
    if (side === COMPONENT_ALIGNMENT.RIGHT) {
      frame.hasRightFrame = hasFrame;
    }

    frame.connections
      .filter((c) => c.attachedTo && c.side === side)
      .forEach((c) => {
        const attachedFrame = newFrames.find((f) => f.connections.find((fc) => fc.id === c.attachedTo) != null);
        adjustPosition(attachedFrame, newFrames, c.side, difference);
      });
  });

  return newFrames;
}

export function calculateDimensions(frames, frameThickness, offset) {
  const _boxSize = new THREE.Vector3();
  const _boxPos = new THREE.Vector3();
  calculateBounds(frames, _boxPos, _boxSize, frameThickness);

  return {
    lengthPosition: [_boxPos.x, _boxPos.y - _boxSize.y / 2 - offset, _boxPos.z + _boxSize.z / 2],
    heightPosition: [_boxPos.x - _boxSize.x / 2 - offset, _boxPos.y, _boxPos.z + _boxSize.z / 2],
    widthPosition: [_boxPos.x - _boxSize.x / 2, _boxPos.y - _boxSize.y / 2 - offset, _boxPos.z],
    lengthSize: _boxSize.x,
    heightSize: _boxSize.y,
    widthSize: _boxSize.z,
    lengthSizeMM: Math.round(toMM(_boxSize.x)),
    heightSizeMM: Math.round(toMM(_boxSize.y)),
    widthSizeMM: Math.round(toMM(_boxSize.z)),
  };
}

export const calculateFurthestFrame = (frames, direction, property) => {
  const positionIndex = property === "length" ? 0 : property === "height" ? 1 : 2;

  return direction < 0
    ? frames.reduce(
        (min, f) => (f.position[positionIndex] - fromMM(f[property] / 2) < min ? f.position[positionIndex] - fromMM(f[property] / 2) : min),
        frames[0].position[positionIndex] - fromMM(frames[0][property] / 2)
      )
    : frames.reduce(
        (max, f) => (f.position[positionIndex] + fromMM(f[property] / 2) > max ? f.position[positionIndex] + fromMM(f[property] / 2) : max),
        frames[0].position[positionIndex] + fromMM(frames[0][property] / 2)
      );
};

export const calculateBounds = (frames, pos, size, frameThickness) => {
  if (frames.length == 1) {
    const frame = frames[0];

    pos.set(frame.position[0], frame.position[1], frame.position[2]);

    size.set(fromMM(frame.length), fromMM(frame.height), fromMM(frame.width));
  } else {
    // get furthest on each side

    let x1 = calculateFurthestFrame(frames, -1, "length");
    let x2 = calculateFurthestFrame(frames, 1, "length");

    let y1 = calculateFurthestFrame(frames, -1, "height");
    let y2 = calculateFurthestFrame(frames, 1, "height");

    let z1 = calculateFurthestFrame(frames, -1, "width");
    let z2 = calculateFurthestFrame(frames, 1, "width");

    // calculate totals

    const totalLength = x2 - x1 + fromMM(frameThickness) * 2;
    const totalHeight = y2 - y1;
    const totalWidth = z2 - z1;

    size.set(totalLength, totalHeight, totalWidth);
    pos.set((x1 + x2) / 2, (y1 + y2) / 2, (z1 + z2) / 2);

    //
  }
};

export function createSVGDimensions(frames, frameThickness, offset, projection, groupSection) {
  let svg = "";

  generateSVGLines(frames, offset, true);

  if (groupSection) {
    const sectionIds = [...new Set(frames.filter((f) => f.sectionId != null).map((f) => f.sectionId))];

    sectionIds.forEach((sectionId) => {
      generateSVGLines(
        frames.filter((f) => f.sectionId == sectionId),
        offset / 2,
        false
      );
    });

    frames
      .filter((f) => !f.sectionId)
      .forEach((frame, i) => {
        generateSVGLines([frame], offset / 2, false);
      });
  } else {
    frames.forEach((frame, i) => {
      generateSVGLines([frame], offset / 2, false);
    });
  }

  function generateSVGLines(frames, offset, includeSide) {
    const _boxSize = new THREE.Vector3();
    const _boxPos = new THREE.Vector3();
    calculateBounds(frames, _boxPos, _boxSize, frameThickness);

    const dims = calculateDimensions(frames, frameThickness, 0);
    const strokeWidth = 0.0015;

    if (projection == 0) {
      // Top projection

      // Dimensions length
      let x1 = dims.lengthPosition[0] - dims.lengthSize / 2;
      let x2 = dims.lengthPosition[0] + dims.lengthSize / 2;
      let y1 = _boxSize.z / 2 + offset;
      let y2 = y1;

      svg += `<!-- Dimension -->`;
      svg += `<line x1="${x1}" y1="${y1 * 0.9}" x2="${x1}" y2="${y1 * 1.05}" stroke-width="${strokeWidth}" stroke="black" />`;
      svg += `<line x1="${x2}" y1="${y1 * 0.9}" x2="${x2}" y2="${y1 * 1.05}" stroke-width="${strokeWidth}" stroke="black" />`;
      svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
      svg += `<text text-anchor="middle" x="${dims.lengthPosition[0]}" y="${y1 * 0.98}" font-size="0.03" font-family="sans-serif">${dims.lengthSizeMM}</text>`;

      if (includeSide) {
        // Dimensions right
        x1 = _boxSize.x / 2 + offset;
        x2 = x1;
        y1 = dims.widthPosition[2] - dims.widthSize / 2;
        y2 = dims.widthPosition[2] + dims.widthSize / 2;

        svg += `<!-- Dimension -->`;
        svg += `<line x1="${x1 - 0.02}" y1="${y1}" x2="${x1 + 0.04}" y2="${y1}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<line x1="${x1 - 0.02}" y1="${y2}" x2="${x2 + 0.04}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<text text-anchor="middle" x="${x1 * 1.02}" y="${dims.widthPosition[2]}" font-size="0.03" font-family="sans-serif">${dims.widthSizeMM}</text>`;
      }
    } else {
      // Side projection

      // Dimensions length
      let x1 = dims.lengthPosition[0] - dims.lengthSize / 2;
      let x2 = dims.lengthPosition[0] + dims.lengthSize / 2;
      let y1 = _boxSize.y / 2 + offset;
      let y2 = y1;

      svg += `<!-- Dimension -->`;
      svg += `<line x1="${x1}" y1="${y1 * 0.9}" x2="${x1}" y2="${y1 * 1.05}" stroke-width="${strokeWidth}" stroke="black" />`;
      svg += `<line x1="${x2}" y1="${y1 * 0.9}" x2="${x2}" y2="${y1 * 1.05}" stroke-width="${strokeWidth}" stroke="black" />`;
      svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
      svg += `<text text-anchor="middle" x="${dims.lengthPosition[0]}" y="${y1 * 0.98}" font-size="0.03" font-family="sans-serif">${dims.lengthSizeMM}</text>`;

      if (includeSide) {
        // Dimensions right
        x1 = _boxSize.x / 2 + offset;
        x2 = x1;
        y1 = dims.widthPosition[2] - dims.heightSize / 2;
        y2 = dims.widthPosition[2] + dims.heightSize / 2;

        svg += `<!-- Dimension -->`;
        svg += `<line x1="${x1 - 0.02}" y1="${y1}" x2="${x1 + 0.04}" y2="${y1}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<line x1="${x1 - 0.02}" y1="${y2}" x2="${x2 + 0.04}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<text text-anchor="middle" x="${x1 * 1.02}" y="${dims.widthPosition[2]}" font-size="0.03" font-family="sans-serif">${dims.heightSizeMM}</text>`;
      }
    }
  }

  return svg;
}
