import { useEffect } from "react";
import Frame from "./Frame";
import { TOOL_MODE } from "../Constants";
import Draggable from "../Controls/Draggable";
import { calculateAttached } from "../Utils/frameUtils";
import { useStore } from "../Store/zustandStore";
import { useThree } from "@react-three/fiber";
//import { FillPass, SVGRenderer, SVGMesh, VisibleChainPass, HiddenChainPass } from "three-svg-renderer";
import * as THREE from "three";
import { createEdges, edgesToGeometry, edgesToSVG } from "../Utils/edgeUtils";

export default function Unit({ setIsDragging, isDragging, frames, setPickup, pickup, updateUnit, draggingEnabled }) {
  const [toolMode, setToolMode, selectedUUID, setSelectedUUID, frameThickness, requestDrawings, setRequestDrawings, setDrawings, drawingTolerance] = useStore((state) => [
    state.toolMode,
    state.setToolMode,
    state.selectedUUID,
    state.setSelectedUUID,
    state.frameThickness,
    state.requestDrawings,
    state.setRequestDrawings,
    state.setDrawings,
    state.drawingTolerance,
  ]);
  const { scene } = useThree();

  useEffect(() => {
    updateUnit("FRAMES", -1, calculateAttached(frames, frameThickness));
  }, []);

  useEffect(() => {
    if (selectedUUID == null || selectedUUID.length == 0) setToolMode(TOOL_MODE.SELECTION);
  }, [selectedUUID]);

  useEffect(() => {
    if (requestDrawings) {
      setRequestDrawings(false);

      const drawings = [];
      const sectionIds = [...new Set(frames.filter((f) => f.sectionId != null).map((f) => f.sectionId))];

      var edges = createEdges(scene, frames, false, drawingTolerance);
      var svg = edgesToSVG(edges, frames, frameThickness, 0.25, 0);
      edges = createEdges(scene, frames, true, drawingTolerance);
      var svg_side = edgesToSVG(edges, frames, frameThickness, 0.25, 1);
      drawings.push({ name: "Main", top: "data:image/svg+xml;base64," + btoa(svg), side: "data:image/svg+xml;base64," + btoa(svg_side) });

      sectionIds.forEach((sectionId) => {
        edges = createEdges(scene, frames, false, drawingTolerance, sectionId);
        svg = edgesToSVG(edges, frames, frameThickness, 0.25, 0, sectionId);
        edges = createEdges(scene, frames, true, drawingTolerance, sectionId);
        svg_side = edgesToSVG(edges, frames, frameThickness, 0.25, 1, sectionId);
        drawings.push({ name: "Section", top: "data:image/svg+xml;base64," + btoa(svg), side: "data:image/svg+xml;base64," + btoa(svg_side) });
      });

      setDrawings(drawings);
    }
  }, [requestDrawings]);

  const handleSelection = (e, f) => {
    e.stopPropagation();

    if (toolMode !== TOOL_MODE.SELECTION || (selectedUUID?.length > 1 && selectedUUID.includes(f.id))) return;

    let selectedFrames = [f.id];

    if (f.sectionId) {
      const framesInSection = frames.filter((frame) => frame.sectionId == f.sectionId && frame.id !== f.id).map((frame) => frame.id);
      selectedFrames = selectedFrames.concat(framesInSection);
    }

    if (e.shiftKey) {
      setSelectedUUID(selectedUUID ? selectedUUID.concat(selectedFrames) : selectedFrames);
    } else {
      setSelectedUUID(selectedFrames);
    }
  };

  return (
    <Draggable enabled={draggingEnabled} pickup={pickup} isDragging={isDragging} setIsDragging={setIsDragging} updateUnit={updateUnit} selectedUUID={selectedUUID} frames={frames}>
      {frames.map((f) => (
        <Frame
          key={f.id}
          data={f}
          selected={selectedUUID?.includes(f.id)}
          setPickup={setPickup}
          isDragging={isDragging}
          handleSelection={handleSelection}
          updateUnit={updateUnit}
          frames={frames}
        ></Frame>
      ))}
    </Draggable>
  );
}
