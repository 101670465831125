import { Html } from "@react-three/drei";
import { useEffect, useState } from "react";
import { Vector3 } from "three";
import { useStore } from "../Store/zustandStore";
import { calculateBounds, calculateDimensions, fromMM, toMM } from "../Utils/frameUtils";

export default function Dimensions({ frames, enabled }) {
  const [frameThickness] = useStore((state) => [state.frameThickness]);

  return enabled && frames?.length > 0 ? (
    <group userData={{ type: "dimensions" }}>
      <Dimension frames={frames} showHeight={true} showLength={true} showWidth={true} frameThickness={frameThickness}></Dimension>
      {frames.map((f) => {
        return <Dimension key={f.id} frames={[f]} showLength={true} offset={0.1}></Dimension>;
      })}
    </group>
  ) : null;
}

function Dimension({ showLength, showHeight, showWidth, frames, frameThickness, offset = 0.25 }) {
  const [positionAndSize, setPositionAndSize] = useState();
  useEffect(() => {
    if (!frames || frames.length == 0) return;
    setPositionAndSize(calculateDimensions(frames, frameThickness, offset));
  }, [frames]);

  return (
    <group userData={{ type: "dimensions" }}>
      {positionAndSize && (
        <>
          {showLength && (
            <Html position={positionAndSize.lengthPosition} center transform distanceFactor={1}>
              <div
                className="dimension-length"
                style={{
                  width: positionAndSize.lengthSize * 400,
                }}
              >
                <div>
                  <span>{positionAndSize.lengthSizeMM}</span>
                </div>
              </div>
            </Html>
          )}
          {showHeight && (
            <Html position={positionAndSize.heightPosition} center transform distanceFactor={1}>
              <div className="dimension-height" style={{ height: positionAndSize.heightSize * 400 }}>
                <div>
                  <span>{positionAndSize.heightSizeMM}</span>
                </div>
              </div>
            </Html>
          )}
          {showWidth && (
            <Html position={positionAndSize.widthPosition} rotation-y={-Math.PI / 2} center transform distanceFactor={1}>
              <div className="dimension-width" style={{ width: positionAndSize.widthSize * 400 }}>
                <div>
                  <span>{positionAndSize.widthSizeMM}</span>
                </div>
              </div>
            </Html>
          )}
        </>
      )}
    </group>
  );
}
