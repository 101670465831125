import * as THREE from "three";
import { Edges } from "@react-three/drei";
import { calculationComponentPosition, fromMM } from "../Utils/frameUtils";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { useMemo } from "react";
import { buildBagFilter, buildDuctPlateWithHole } from "../Utils/geometryUtils";
import { COMPONENT_ALIGNMENT } from "../Constants";

export default function BagFilter({ frame, getColour }) {
  const frameWidth = fromMM(frame.width);
  const frameHeight = fromMM(frame.height);

  const filterTotalHeight = fromMM(frame.height) - fromMM(frame.bagFilter?.gap || 50) * 2;
  const filterThickness = fromMM(frame.bagFilter?.filterThickness || 40);
  const filterLength = fromMM(frame.bagFilter?.filterLength || 150);

  const requiredFilters = Math.floor(frameWidth / filterThickness);

  const twoRows = frame.bagFilter != null ? frame.bagFilter.twoRows : true;

  const bottomFilterHeight = filterTotalHeight * (twoRows ? 0.7 : 1);
  const topFilterHeight = filterTotalHeight * 0.3;

  const filterGeometry = useMemo(() => {
    return buildBagFilter(bottomFilterHeight, filterLength, filterThickness / 2, requiredFilters);
  }, [bottomFilterHeight, filterThickness, requiredFilters]);

  const filterGeometry2 = useMemo(() => {
    return buildBagFilter(topFilterHeight, filterLength, filterThickness / 2, requiredFilters);
  }, [topFilterHeight, filterThickness, requiredFilters]);

  const backPlate = useMemo(() => {
    return buildDuctPlateWithHole(frameHeight, frameWidth, false, frameWidth * 0.8, frameHeight * 0.8, 0, frameHeight * 0.1);
  }, [frameHeight, frameWidth]);

  return (
    <group position={calculationComponentPosition(filterLength, frame)}>
      <mesh geometry={backPlate} rotation-y={Math.PI / 2} position={[((frame.componentAlignment == COMPONENT_ALIGNMENT.LEFT ? -1 : 1) * filterLength) / 2, 0, 0]}>
        <meshStandardMaterial color={getColour("DarkGrey")} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        rotation-y={frame.componentAlignment == COMPONENT_ALIGNMENT.RIGHT ? Math.PI : 0}
        position={[0, -(filterTotalHeight - bottomFilterHeight) / 2, 0]}
        geometry={filterGeometry}
      >
        <meshStandardMaterial color={"BurlyWood"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
      {twoRows && (
        <mesh
          castShadow
          receiveShadow
          rotation-y={frame.componentAlignment == COMPONENT_ALIGNMENT.RIGHT ? Math.PI : 0}
          position={[0, (filterTotalHeight - topFilterHeight) / 2, 0]}
          geometry={filterGeometry2}
        >
          <meshStandardMaterial color={"BurlyWood"} />
          <Edges scale={1} renderOrder={1000}>
            <meshBasicMaterial transparent color="#111" depthTest={true} />
          </Edges>
        </mesh>
      )}
    </group>
  );
}
