export const DEFAULT_EMPTY_LENGTH = 500;
export const DEFAULT_EMPTY_WIDTH = 500;
export const DEFAULT_EMPTY_HEIGHT = 500;
export const DEFAULT_EMPTY_THICKNESS = 35;

export const CONNECTION_LENGTH = 0.2;

export const DIRECTION_EXTRACT = 0;
export const DIRECTION_SUPPLY = 1;

export const POSITION_VERTICAL = 0;
export const POSITION_HORIZONTAL = 1;

export const COMPONENT_TYPES = {
  EMPTY: -1,
  COIL: 0,
  FILTER: 1,
  DAMPER: 2,
  FAN: 3,
  GRILL: 4,
  DUCT: 5,
  PLATE_HEAT_EXCHANGER: 6,
  ROTARY_HEAT_EXCHANGER: 7,
  UV_LIGHTS: 8,
  CARBON_FILTER: 9,
  BAG_FILTER: 10,
  OZONE: 11,
  ODOUR_NEUTRALISER: 12,
};

export const COMPONENT_ALIGNMENT = {
  LEFT: -1,
  CENTER: 0,
  RIGHT: 1,
  TOP: 2,
  BOTTOM: 3,
};

export const COMPONENT_SIDE = {
  LEFT: -1,
  RIGHT: 1,
};

export const TOOL_MODE = {
  SELECTION: 0,
  MOVE: 1,
};
