import { Html } from "@react-three/drei";
import { useState } from "react";

export default function FloatingButton({ initialIconClass, position, active, children, onToggleMenu, onOcclude }) {
  function toggleMenu() {
    if (buttonHidden) return;
    if (onToggleMenu) onToggleMenu();
  }

  const [buttonHidden, setButtonHidden] = useState();

  return (
    <Html
      occlude
      onOcclude={(value) => {
        setButtonHidden(value);
        if (onOcclude) onOcclude(value);
      }}
      style={{
        //transition: "all 0.5s",
        opacity: buttonHidden ? 0 : 1,
        transform: `scale(${buttonHidden ? 0.5 : 1})`,
      }}
      position={position}
    >
      <div className={active ? "circular-menu active" : "circular-menu"}>
        <a className="floating-btn" onClick={toggleMenu}>
          <i className={`fa ${initialIconClass || "fa-plus"}`}></i>
        </a>
        <menu className="items-wrapper">{children}</menu>
      </div>
    </Html>
  );
}
