import { useEffect, useRef, useState } from "react";
import { extend, useThree } from "@react-three/fiber";
import { DragControls } from "./THREE/DragControls";
import * as THREE from "three";
import { TOOL_MODE } from "../Constants";
import { calculateAttached } from "../Utils/frameUtils";
import { useStore } from "../Store/zustandStore";

extend({ DragControls });

const raycaster = new THREE.Raycaster();

function Draggable({ enabled, pickup, children, isDragging, setIsDragging, updateUnit, frames }) {
  const [selectedUUID, toolMode, frameThickness] = useStore((state) => [state.selectedUUID, state.toolMode, state.frameThickness]);

  const groupRef = useRef();
  const transformGroupRef = useRef();
  const controlsRef = useRef();
  const [objects, setObjects] = useState();
  const { camera, gl, scene } = useThree();

  function groupFrames() {
    const draggableobjects = controlsRef.current.getObjects();

    // Transform group

    for (let i = 0; i < selectedUUID.length; i++) {
      const uuid = selectedUUID[i];
      const child = objects.find((o) => o.uuid == uuid);
      if (child) transformGroupRef.current.attach(child);
    }

    draggableobjects.push(transformGroupRef.current);
    controlsRef.current.transformGroup = true;
  }

  function ungroupFrames() {
    const draggableobjects = controlsRef.current.getObjects();
    controlsRef.current.transformGroup = false;

    for (let i = transformGroupRef.current.children.length - 1; i >= 0; i--) {
      const child = transformGroupRef.current.children[i];
      groupRef.current.attach(child);
    }

    const index = draggableobjects.indexOf(transformGroupRef.current);
    if (index > -1) draggableobjects.splice(draggableobjects.indexOf(transformGroupRef.current), 1);
  }

  useEffect(() => {
    if (controlsRef.current) controlsRef.current.enabled = toolMode == TOOL_MODE.MOVE;
  }, [toolMode]);

  useEffect(() => {
    if (!objects || objects.length == 0) return;

    ungroupFrames();

    if (selectedUUID?.length > 1) {
      groupFrames();
    }

    const framesToBeDeleted = frames.filter((f) => f.delete);
    if (framesToBeDeleted.length > 0) {
      const updatedFrames = calculateAttached(frames, frameThickness, objects);
      updateUnit(
        "FRAMES",
        -1,
        updatedFrames.filter((f) => !f.delete)
      );
    }
  }, [selectedUUID]);

  useEffect(() => {
    if (pickup) controlsRef.current.pickup(pickup);
  }, [pickup]);

  useEffect(() => {
    setObjects(groupRef.current.children);
  }, [groupRef, children]);

  useEffect(() => {
    controlsRef.current.enabled = enabled;
  }, [enabled]);

  useEffect(() => {
    const dragStart = ({ event, object }) => {
      scene.orbitControls.enabled = false;
      setIsDragging(object.uuid);
    };

    const dragEnd = (event) => {
      scene.orbitControls.enabled = true;
      setIsDragging(false);

      // Update positions in group
      const group = transformGroupRef.current;
      if (group?.children.length > 0) {
        for (let i = 0; i < group.children.length; i++) {
          const child = group.children[i];
          child.position.applyMatrix4(group.matrix);
          child.updateMatrixWorld();
        }
        group.position.set(0, 0, 0);
        group.updateMatrixWorld();
      }

      updateUnit("FRAMES", -1, calculateAttached(frames, frameThickness, objects));
    };

    const drag = (event) => {
      // Raycast to see if over connections
      const selectedObject = event.object;
      const otherFrames = objects.filter((c) => c.userData?.type === "frame" && c.uuid !== selectedObject.uuid);
      raycaster.set(event.ray.origin, event.ray.direction);
      const intersects = raycaster.intersectObjects(otherFrames, true);
      if (intersects.length > 0) {
        intersects.forEach((intersect) => {
          if (intersect.object.userData.type == "connection") {
            snapToConnection(intersect.object, selectedObject, frames, frameThickness);
          }
        });
      }
    };

    controlsRef.current.addEventListener("dragstart", dragStart);
    controlsRef.current.addEventListener("dragend", dragEnd);
    controlsRef.current.addEventListener("drag", drag);

    return () => {
      controlsRef.current.removeEventListener("dragstart", dragStart);
      controlsRef.current.removeEventListener("dragend", dragEnd);
      controlsRef.current.removeEventListener("drag", drag);
    };
  }, [objects, scene.orbitControls, frames]);

  return (
    <group ref={groupRef} userData={{ type: "masterGroup" }}>
      <dragControls ref={controlsRef} args={[objects, camera, gl.domElement]} />
      {children}
      <group ref={transformGroupRef}></group>
    </group>
  );
}

function snapToConnection(connectionPoint, selectedObject, frames, frameThickness) {
  // Get frames
  const targetFrame = frames.find((f) => f.connections.find((c) => c.id == connectionPoint.uuid) != null);
  const connectionData = connectionPoint.userData.connection; // targetFrame.connections.find((c) => c.id == connectionPoint.uuid);

  const connectionPosition = new THREE.Vector3();
  connectionPoint.getWorldPosition(connectionPosition);

  let group = null;

  if (selectedObject instanceof THREE.Group) {
    group = selectedObject;

    // Find object / frame with connection point opposite direction to connectionPoint and not attached to anything and probably nearest

    const position = new THREE.Vector3();

    let closestObject = null;
    let distance = null;

    for (let i = 0; i < selectedObject.children.length; i++) {
      const object = selectedObject.children[i];

      const frame = frames.find((f) => f.id == object.uuid);
      const connectionIndex = frame.connections.findIndex(
        (c) =>
          c.side !== connectionData.side &&
          !c.attachedTo &&
          (connectionData.position.length === 1 || c.position.every((p) => connectionData.position.includes(p)))
      );
      if (connectionIndex > -1) {
        object.getWorldPosition(position);

        const d = Math.abs(position.distanceTo(connectionPosition));

        if (distance === null || d < distance) {
          distance = d;
          closestObject = object;
        }
      } else {
        continue;
      }
    }

    selectedObject = closestObject;
  }

  if (!selectedObject) return;

  const selectedFrame = frames.find((f) => f.id == selectedObject.uuid);

  // Try to work out target connection
  const targetConnection = selectedFrame.connections.findIndex(
    (c) => c.side !== connectionData.side && (connectionData.position.length === 1 || c.position.every((p) => connectionData.position.includes(p)))
  );

  // Connection point on dragging object
  const thisConnectionPoint = selectedObject.children.filter((c) => c.userData.type === "connection")[targetConnection];

  if (connectionData.attachedTo && connectionData.attachedTo !== thisConnectionPoint.uuid) return;

  // Calculate new position for dragging object
  const thisConnectionPosition = new THREE.Vector3();

  thisConnectionPoint.getWorldPosition(thisConnectionPosition);

  const differenceWorld = new THREE.Vector3().subVectors(connectionPosition, thisConnectionPosition);
  const objectPosWorld = new THREE.Vector3();

  (group || selectedObject).getWorldPosition(objectPosWorld);

  const moveWorld = new THREE.Vector3().addVectors(objectPosWorld, differenceWorld);
  (group || selectedObject).position.copy(moveWorld);
}

export default Draggable;
