import { useMemo, useState } from "react";
import * as THREE from "three";
import { Color } from "three";
import { COMPONENT_ALIGNMENT, COMPONENT_TYPES, CONNECTION_LENGTH, DIRECTION_EXTRACT } from "../Constants";
import { Edges, Html } from "@react-three/drei";
import { useStore } from "../Store/zustandStore";
import CircularMenuButton from "./CircularMenuButton";
import { fromMM } from "../Utils/frameUtils";
import FloatingButton from "./FloatingButton";

export default function Connection({ connection, frameLength, frameHeight, frameWidth, hasFrame, visible, isDragging, attached, addFrame }) {
  const [selectedConnectionMenu, setSelectedConnectionMenu, frameThickness] = useStore((state) => [
    state.selectedConnectionMenu,
    state.setSelectedConnectionMenu,
    fromMM(state.frameThickness),
  ]);

  const geom = useMemo(() => {
    return buildConnectionGeometry(
      CONNECTION_LENGTH,
      frameHeight / connection.position.length,
      frameWidth,
      connection.side === COMPONENT_ALIGNMENT.LEFT ? -1 : 1
    );
  }, [frameWidth, frameHeight]);

  const position = [
    (connection.side === COMPONENT_ALIGNMENT.LEFT ? -1 : 1) * (frameLength / 2 + (hasFrame ? frameThickness : 0)),
    connection.position.length == 1
      ? 0
      : connection.position.indexOf(1) * (frameHeight / connection.position.length) - frameHeight / connection.position.length / 2,
    0,
  ];

  const [buttonHidden, setButtonHidden] = useState();

  function toggleMenu() {
    if (selectedConnectionMenu != connection.id) {
      setSelectedConnectionMenu(connection.id);
    } else {
      setSelectedConnectionMenu(null);
    }
  }

  const handleAdd = (componentType) => {
    if (!visible || buttonHidden) return;
    setSelectedConnectionMenu(null);
    addFrame(connection, position, componentType);
  };

  const onOcclude = (value) => {
    setButtonHidden(value);
  };

  return (
    <>
      {visible && !isDragging && (selectedConnectionMenu == null || selectedConnectionMenu == connection.id) && (
        <FloatingButton
          position={[position[0] + (connection.side === COMPONENT_ALIGNMENT.LEFT ? -0.1 : 0.1), position[1], position[2]]}
          active={selectedConnectionMenu == connection.id}
          onToggleMenu={toggleMenu}
          onOcclude={onOcclude}
        >
          <CircularMenuButton
            iconClass="fa-cube"
            handleClick={() => {
              handleAdd(COMPONENT_TYPES.EMPTY);
            }}
          />
          <CircularMenuButton
            iconClass="fa-window-maximize"
            handleClick={() => {
              handleAdd(COMPONENT_TYPES.FILTER);
            }}
          />
          <CircularMenuButton
            iconClass="fa-fan"
            handleClick={() => {
              handleAdd(COMPONENT_TYPES.FAN);
            }}
          />
          <CircularMenuButton
            iconClass="fa-bars"
            handleClick={() => {
              handleAdd(COMPONENT_TYPES.DAMPER);
            }}
          />
        </FloatingButton>
      )}
      <mesh
        position={position}
        userData={{ type: "connection", connection: connection }}
        visible={visible}
        geometry={geom}
        uuid={connection.id}
        scale={[isDragging ? 1 : 0.1, 1, 1]}
      >
        <meshStandardMaterial
          color={
            connection.direction == null
              ? Color.NAMES.grey
              : connection.direction === DIRECTION_EXTRACT
              ? Color.NAMES.crimson
              : Color.NAMES.dodgerblue
          }
          transparent
          opacity={attached ? 0.05 : 0.5}
        />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial
            transparent
            color={
              connection.direction == null
                ? Color.NAMES.grey
                : connection.direction === DIRECTION_EXTRACT
                ? Color.NAMES.crimson
                : Color.NAMES.dodgerblue
            }
            widthTest={true}
          />
        </Edges>
      </mesh>
    </>
  );
}

function buildConnectionGeometry(length, height, width, side) {
  const geo = new THREE.BoxGeometry(length, height, width);
  geo.applyMatrix4(new THREE.Matrix4().makeTranslation((side * length) / 2, 0, 0));
  return geo;
}
