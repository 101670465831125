import { COMPONENT_SIDE } from "../../Constants";

export default {
  pipeRadius: 10,
  pipeThickness: 5,
  pipeLengthInlet: 150,
  pipeLengthOutlet: 75,

  pipeInletPositionFromTop: 50,
  pipeOutletPositionFromTop: 350,

  pipeInletPositionFromLeft: 20,
  pipeOutletPositionFromLeft: 80,

  inletPipeSide: COMPONENT_SIDE.LEFT,
  outletPipeSide: COMPONENT_SIDE.LEFT,
};
