import { Edges, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { buildCentrifugal } from "../Utils/geometryUtils";
import { useMemo } from "react";
import { fromMM } from "../Utils/frameUtils";
import { COMPONENT_ALIGNMENT } from "../Constants";
import Duct from "./Duct";
import { MathUtils } from "three";
import { useStore } from "../Store/zustandStore";

export default function Fan({ frame, selected, fanLength, fanHeight, fanWidth }) {
  //const [size, setSize] = useState(new THREE.Vector3());
  /* const group = useRef();
  useLayoutEffect(() => {
    if (!group) return;

    const aabb = new THREE.Box3();
    aabb.setFromObject(group.current);
    setSize(aabb.getSize(new THREE.Vector3()));
  }, []); */

  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);

  const width = fromMM(frame.width) * 0.6;
  const length = fromMM(frame.length);
  const height = fromMM(frame.height);

  const size = Math.min(length * 0.6, height * 0.6);
  const outletHeight = size * 0.675;

  const mountingFrameHeight = fromMM(20);
  const feetHeight = fromMM(20);

  const hasBase = true;
  const hasFeet = true;

  const alignment = frame.componentAlignment;

  const rotation = MathUtils.degToRad(frame.fan?.angle || 0);

  const position = [
    alignment === COMPONENT_ALIGNMENT.LEFT ? -(length / 2 + size / 2) : length / 2 - size / 2,
    -height / 2 + size / 2 + (hasBase ? mountingFrameHeight / 2 + (hasFeet ? feetHeight / 2 : 0) : 0),
    0,
  ];

  const flangeDistanceFromTop =
    frame.flangeAlignment !== COMPONENT_ALIGNMENT.TOP
      ? height - (size + (hasBase ? mountingFrameHeight / 2 + (hasFeet ? feetHeight / 2 : 0) : 0))
      : size - outletHeight;

  const fan_geometry = useMemo(() => {
    return buildCentrifugal(size, width, outletHeight);
  }, [size, frameThickness, width]);

  return (
    <group>
      <group position={position} rotation={[0, 0, rotation]}>
        {fan_geometry.map((g, i) => {
          return (
            <mesh key={i} geometry={g}>
              <meshStandardMaterial color={"SlateGrey"} side={THREE.DoubleSide} />
              <Edges scale={1} renderOrder={1000}>
                <meshBasicMaterial transparent color="#526477" widthTest={true} />
              </Edges>
            </mesh>
          );
        })}
      </group>
      {hasBase && (
        <mesh position={[0, -height / 2 + mountingFrameHeight / 2, 0]}>
          <boxGeometry args={[length * 0.9, mountingFrameHeight, width]} />
          <meshStandardMaterial color="#444" />
        </mesh>
      )}
      {frame.hasFlange && (
        <Duct
          frame={frame}
          getColour={() => (selected ? "Orange" : "DarkGrey")}
          flangeWidth={width}
          flangeHeight={outletHeight}
          flangeDistanceFromTop={flangeDistanceFromTop}
        />
      )}
    </group>
  );
}

useGLTF.preload("/fan.glb");
