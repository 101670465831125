import * as THREE from "three";
import { useMemo } from "react";
import { Edges } from "@react-three/drei";
import { COMPONENT_ALIGNMENT } from "../Constants";
import DuctProperties from "./Properties/DuctProperties";
import { fromMM } from "../Utils/frameUtils";
import { buildCurvedDuct, buildDuct, buildDuctPlateWithHole } from "../Utils/geometryUtils";
import { useStore } from "../Store/zustandStore";

export default function Duct({ flangeWidth, flangeHeight, flangeDistanceFromTop, frame, getColour }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);

  const width = fromMM(frame.width);
  const height = fromMM(frame.height);
  const length = fromMM(frame.length);
  const alignment = frame.flangeAlignment || COMPONENT_ALIGNMENT.LEFT;

  const { duct } = frame;
  const shape = duct?.shape || DuctProperties.shape.value;
  const size = duct?.size || DuctProperties.size;
  const outletLength = fromMM(50);

  const hasFrame = frame.hasFrame;
  const isCurved = shape === "Curved";
  const componentLength = isCurved ? length : -0.01;
  const insideFrame = isCurved;

  const position = [
    // no frame
    hasFrame === false || insideFrame
      ? alignment === COMPONENT_ALIGNMENT.LEFT
        ? length / 2 - componentLength / 2
        : alignment === COMPONENT_ALIGNMENT.RIGHT
        ? -length / 2 + componentLength / 2
        : 0
      : // has frame
      alignment === COMPONENT_ALIGNMENT.LEFT
      ? -(length / 2 + componentLength / 2 + frameThickness)
      : alignment === COMPONENT_ALIGNMENT.RIGHT
      ? componentLength / 2 + length / 2 + frameThickness
      : 0,
    alignment === COMPONENT_ALIGNMENT.TOP ? frameThickness + height / 2 - 0.005 : isCurved ? frameThickness : 0,
    0,
  ];

  var extrudeSettings = {
    depth: outletLength,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };

  const geom = useMemo(() => {
    return isCurved
      ? buildCurvedDuct(length - 0.05, height - 0.05, length, height, width, 0.01)
      : buildDuctPlateWithHole(
          (alignment === COMPONENT_ALIGNMENT.TOP ? length : height) * 0.999, // - frameThickness * 2,
          (alignment === COMPONENT_ALIGNMENT.TOP ? width : width) * 0.999, //- frameThickness * 2,
          shape === "Radial",
          flangeWidth,
          flangeHeight,
          (width * size) / 2,
          flangeDistanceFromTop
        );
  }, [height, width, shape, size, length, flangeDistanceFromTop, alignment]);

  const duct_geometry = useMemo(() => {
    return buildDuct(
      alignment === COMPONENT_ALIGNMENT.TOP ? length : height, // - frameThickness,
      alignment === COMPONENT_ALIGNMENT.TOP ? width : width, // - frameThickness,
      shape === "Radial",
      flangeWidth,
      flangeHeight,
      (width * size) / 2,
      flangeDistanceFromTop
    );
  }, [height, length, width, shape, size, alignment, flangeDistanceFromTop]);

  return (
    <>
      <group
        rotation={[
          alignment === COMPONENT_ALIGNMENT.TOP ? -Math.PI / 2 : 0,
          alignment === COMPONENT_ALIGNMENT.TOP ? 0 : alignment !== COMPONENT_ALIGNMENT.LEFT ? Math.PI / 2 : -Math.PI / 2,
          alignment === COMPONENT_ALIGNMENT.TOP ? -Math.PI / 2 : 0,
        ]}
        position={position}
      >
        <mesh geometry={geom}>
          <meshStandardMaterial color={getColour("DarkGrey")} />
          {isCurved && (
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="Grey" widthTest={true} />
            </Edges>
          )}
        </mesh>
        {!isCurved && (
          <mesh>
            <extrudeGeometry args={[duct_geometry, extrudeSettings]} />
            <meshStandardMaterial color={getColour("DarkGrey")} side={THREE.DoubleSide} />
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="Grey" widthTest={true} />
            </Edges>
          </mesh>
        )}
      </group>
    </>
  );
}
