import { Edges } from "@react-three/drei";
import { Color } from "three";
import { COMPONENT_TYPES } from "../Constants";
import { fromMM } from "../Utils/frameUtils";

export default function HeatExchanger({ frame }) {
  const length = fromMM(frame.length);
  const height = fromMM(frame.height);
  const width = fromMM(frame.width);

  return (
    <group>
      {frame.component === COMPONENT_TYPES.PLATE_HEAT_EXCHANGER ? (
        <>
          <mesh castShadow receiveShadow rotation={[0, 0, Math.PI / 4]}>
            <boxGeometry args={[Math.min(length, height) / 2, Math.min(length, height) / 2, width / 1.1]} />
            <meshStandardMaterial color={Color.NAMES.crimson} />
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="#111" widthTest={true} />
            </Edges>
          </mesh>
          <mesh castShadow receiveShadow>
            <boxGeometry args={[0.01, height * 0.95, width]} />
            <meshStandardMaterial color={Color.NAMES.dodgerblue} />
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="#111" widthTest={true} />
            </Edges>
          </mesh>
          <mesh castShadow receiveShadow>
            <boxGeometry args={[length, 0.01, width]} />
            <meshStandardMaterial color={Color.NAMES.dodgerblue} />
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="#111" widthTest={true} />
            </Edges>
          </mesh>
        </>
      ) : (
        <>
          <mesh castShadow receiveShadow rotation={[0, 0, Math.PI / 2]}>
            <cylinderGeometry args={[Math.min(width, height) / 2, Math.min(width, height) / 2, length / 1.5, 32]} />
            <meshStandardMaterial color={Color.NAMES.crimson} />
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="#111" widthTest={true} />
            </Edges>
          </mesh>
        </>
      )}
    </group>
  );
}
