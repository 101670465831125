import { useMemo } from "react";
import { Edges } from "@react-three/drei";
import * as THREE from "three";
import { buildHollowFrame } from "../Utils/geometryUtils";
import { calculationComponentPosition, fromMM } from "../Utils/frameUtils";

export default function Filter({ frame, length, height }) {
  const width = fromMM(frame.width);
  const filterFrameThickness = 0.01;
  const filterGrade = frame.filter?.grade || "F7";

  const filterFrameGeometry = useMemo(() => {
    return buildHollowFrame(length, height, width, filterFrameThickness);
  }, [length, height, width]);

  return (
    <group dispose={null} position={calculationComponentPosition(length, frame)}>
      <mesh geometry={filterFrameGeometry}>
        <meshStandardMaterial color={filterGrade === "F7" ? "White" : "Green"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh>
        <boxGeometry args={[length - filterFrameThickness, height - filterFrameThickness, width - filterFrameThickness]} />
        <meshStandardMaterial color={filterGrade === "F7" ? "SteelBlue" : "Yellow"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
