import * as THREE from "three";
import { useRef, useState, useLayoutEffect } from "react";
import { Edges, useGLTF } from "@react-three/drei";
import Dampers from "./Dampers";
import { calculationComponentPosition } from "../Utils/frameUtils";

export default function Damper(props) {
  const { nodes } = useGLTF("/damper.glb");
  const [size, setSize] = useState(new THREE.Vector3());
  const { frame } = props;

  const group = useRef();
  useLayoutEffect(() => {
    if (!group) return;

    const aabb = new THREE.Box3();
    aabb.setFromObject(group.current);
    setSize(aabb.getSize(new THREE.Vector3()));
  }, []);

  return (
    <>
      <group ref={group} {...props} dispose={null} position={calculationComponentPosition(size.x, frame)}>
        <mesh castShadow receiveShadow geometry={nodes.Frame.geometry}>
          <meshStandardMaterial color={"SlateGrey"} />
          <Edges scale={1} renderOrder={1000}>
            <meshBasicMaterial transparent color="#111" depthTest={true} />
          </Edges>
        </mesh>
        <Dampers position={[0, 0.36, 0]} bladeSize={[0.18, 0.01, 0.9]} rotation={THREE.MathUtils.degToRad(0)} />
        <mesh castShadow receiveShadow geometry={nodes.Rods.geometry}>
          <meshStandardMaterial color={"SlateGrey"} />
          <Edges scale={1} renderOrder={1000}>
            <meshBasicMaterial transparent color="#111" depthTest={true} />
          </Edges>
        </mesh>
      </group>
    </>
  );
}

useGLTF.preload("/damper.glb");
