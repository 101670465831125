import { Button, Card, CardBody, HStack, NumberInput, NumberInputField, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useStore } from "./Store/zustandStore";

export default function Drawings() {
  const [setRequestDrawings, drawings, drawingTolerance, setDrawingTolerance] = useStore((state) => [
    state.setRequestDrawings,
    state.drawings,
    state.drawingTolerance,
    state.setDrawingTolerance,
  ]);

  return (
    <>
      <HStack spacing="24px">
        <Button colorScheme={"teal"} variant={"solid"} size="xs" onClick={() => setRequestDrawings(true)}>
          Refresh drawings
        </Button>
        <NumberInput
          size="xs"
          maxW={100}
          defaultValue={drawingTolerance}
          step={1}
          min={1}
          onBlur={(e) => {
            const value = parseFloat(e.target.value);
            setDrawingTolerance(value);
          }}
        >
          <NumberInputField id="drawingTolerance" paddingInlineEnd={1} />
        </NumberInput>
      </HStack>

      <Tabs variant="unstyled" size="md" style={{ marginTop: 20 }}>
        <TabList borderBottom={"solid 1px #dedede"} backgroundColor={"white"}>
          {drawings?.length > 0 &&
            drawings.map((d, i) => {
              return (
                <Tab key={i} fontSize={12} _selected={{ fontWeight: "bold" }}>
                  {d.name}
                </Tab>
              );
            })}
        </TabList>

        <TabPanels>
          {drawings?.length > 0 &&
            drawings.map((d, i) => {
              return (
                <TabPanel key={i}>
                  <h4>Side view</h4>
                  <hr></hr>
                  <br></br>
                  <img src={d.side} />
                  <h4>Top view</h4>
                  <hr></hr>
                  <br></br>
                  <img src={d.top} />
                </TabPanel>
              );
            })}
        </TabPanels>
      </Tabs>
    </>
  );
}
