import { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Divider,
  SimpleGrid,
  Button,
  Box,
  Input,
  NumberInput,
  NumberInputField,
  HStack,
  ButtonGroup,
  Checkbox,
  Select,
  IconButton,
} from "@chakra-ui/react";
import * as THREE from "three";
import { COMPONENT_ALIGNMENT, COMPONENT_TYPES, DEFAULT_EMPTY_THICKNESS } from "../Constants";
import { ArrowBackIcon, ArrowForwardIcon, DeleteIcon } from "@chakra-ui/icons";
import FilterProperties from "../Components/Properties/FilterProperties";
import UVProperties from "../Components/Properties/UVProperties";
import CarbonFilterProperties from "../Components/Properties/CarbonFilterProperties";
import BagFilterProperties from "../Components/Properties/BagFilterProperties";
import DuctProperties from "../Components/Properties/DuctProperties";
import CoilProperties from "../Components/Properties/CoilProperties";
import FanProperties from "../Components/Properties/FanProperties";
import DoorProperties from "../Components/Properties/DoorProperties";
import { fromMM, adjustPosition, toggleHasFrame } from "../Utils/frameUtils";
import { useStore } from "../Store/zustandStore";

const parse = (val) => val.replace(/^\$/, "");
const allEqual = (arr) => arr.every((v) => v === arr[0]);

export default function Inspector({ frames, updateUnit, addFrame }) {
  const [selectedUUID, setSelectedUUID, frameThickness, setFrameThickness, setRequestDrawings, drawings] = useStore((state) => [
    state.selectedUUID,
    state.setSelectedUUID,
    state.frameThickness,
    state.setFrameThickness,
    state.setRequestDrawings,
    state.drawings,
  ]);
  const [selectedFrame, setSelectedFrame] = useState(null);

  const multiSelect = selectedFrame?.length > 1;

  useEffect(() => {
    setSelectedFrame(frames && selectedUUID ? frames.filter((frame) => selectedUUID.includes(frame.id)) : null);
  }, [selectedUUID, frames]);

  const getFrameValues = (property, defaultValue) => {
    return selectedFrame?.length > 0 && (!multiSelect || allEqual(selectedFrame.map((f) => f[property]))) ? selectedFrame[0][property] : defaultValue;
  };

  const getComponentPropertyValues = (componentType, property, defaultValue) => {
    return selectedFrame?.length > 0 &&
      (!multiSelect || allEqual(selectedFrame.map((f) => (f[componentType] ? f[componentType][property] : undefined))))
      ? selectedFrame[0][componentType]
        ? selectedFrame[0][componentType][property]
        : undefined
      : defaultValue;
  };

  const selectedIds = selectedFrame?.map((f) => f.id);
  const component = getFrameValues("component", -1);
  const createKey = (id, property) => `${id}-${property}`;
  const includesSection = selectedFrame?.filter((f) => f.sectionId).length > 0;

  return (
    <div
      style={{
        height: "calc(100vh - 85px)",
        width: "350px",
        background: "white",
        borderLeft: "1px solid #dedede",
      }}
    >
      <Tabs variant="unstyled" size="md">
        <TabList borderBottom={"solid 1px #dedede"}>
          {selectedFrame && (
            <Tab fontSize={12} _selected={{ fontWeight: "bold" }}>
              Design
            </Tab>
          )}
          <Tab fontSize={12} _selected={{ fontWeight: "bold" }}>
            Unit settings
          </Tab>
          <Tab fontSize={12} _selected={{ fontWeight: "bold" }}>
            Samples
          </Tab>
        </TabList>
        <TabPanels>
          {selectedFrame && (
            <TabPanel px={0} overflowY="auto" maxHeight="calc(100vh - 35px - 85px)">
              <Box p={4} pt={0}>
                <HStack spacing={4}>
                  <IconButton
                    aria-label="Delete frame"
                    size="sm"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      updateUnit(
                        "FRAMES",
                        -1,
                        frames.map((f) => {
                          if (selectedIds.includes(f.id)) {
                            return { ...f, delete: true, visible: false };
                          } else {
                            return f;
                          }
                        })
                      );

                      setSelectedUUID(null);
                    }}
                    colorScheme="red"
                  />
                </HStack>
              </Box>

              {multiSelect && (
                <>
                  <Text mb={1} mx={4} fontSize="xs">
                    <b>Frame section</b>
                  </Text>
                  <Box p={4}>
                    <HStack spacing={4}>
                      {includesSection ? (
                        <>
                          <Button
                            size="xs"
                            onClick={() => {
                              updateUnit("FRAME", selectedIds, {
                                sectionId: "",
                              });
                            }}
                            colorScheme={"red"}
                            variant={"solid"}
                          >
                            Remove sectioning
                          </Button>
                        </>
                      ) : (
                        <Button
                          size="xs"
                          onClick={() => {
                            const sectionId = THREE.MathUtils.generateUUID();
                            //let newFrames = toggleHasFrame(false, COMPONENT_ALIGNMENT.LEFT, frames, selectedIds, frameThickness);
                            //newFrames = toggleHasFrame(false, COMPONENT_ALIGNMENT.RIGHT, newFrames, selectedIds, frameThickness);

                            const newFrames = [...frames];

                            selectedIds.forEach((id) => {
                              const frame = newFrames.find((f) => f.id === id);
                              frame.sectionId = sectionId;
                            });

                            updateUnit("FRAMES", -1, newFrames);
                          }}
                          colorScheme={"green"}
                          variant={"solid"}
                        >
                          Make section
                        </Button>
                      )}
                    </HStack>
                  </Box>
                </>
              )}
              {!includesSection && (
                <>
                  <Text mb={1} mx={4} fontSize="xs">
                    <b>Frame dimensions</b>
                  </Text>
                  <Box p={4}>
                    <HStack spacing="24px">
                      <Text fontSize="xs">L</Text>
                      <NumberInput
                        size="xs"
                        maxW={20}
                        defaultValue={getFrameValues("length")}
                        step={0.1}
                        min={0.1}
                        key={createKey(selectedIds, "length")}
                        onBlur={(e) => {
                          const newLength = parseFloat(parse(e.target.value));
                          const difference = fromMM(newLength - frames.find((f) => f.id === selectedIds[0]).length) / 2;
                          const newFrames = [...frames];

                          selectedIds.forEach((id) => {
                            const frame = newFrames.find((f) => f.id === id);
                            frame.length = newLength;
                            frame.position = [frame.position[0] + difference, frame.position[1], frame.position[2]];

                            frame.connections
                              .filter((c) => c.attachedTo && c.side === COMPONENT_ALIGNMENT.RIGHT)
                              .forEach((c) => {
                                const attachedFrame = newFrames.find((f) => f.connections.find((fc) => fc.id === c.attachedTo) != null);
                                adjustPosition(attachedFrame, newFrames, c.side, difference * 2);
                              });
                          });

                          updateUnit("FRAMES", -1, newFrames);
                        }}
                      >
                        <NumberInputField id="length" paddingInlineEnd={1} />
                      </NumberInput>
                      <Text fontSize="xs">H</Text>
                      <NumberInput
                        size="xs"
                        maxW={20}
                        defaultValue={getFrameValues("height")}
                        step={0.1}
                        min={0.1}
                        key={createKey(selectedIds, "height")}
                        onBlur={(e) => {
                          updateUnit("FRAME", selectedIds, {
                            height: parseFloat(parse(e.target.value)),
                          });
                        }}
                      >
                        <NumberInputField id="height" paddingInlineEnd={1} />
                      </NumberInput>
                      <Text fontSize="xs">W</Text>
                      <NumberInput
                        size="xs"
                        maxW={20}
                        defaultValue={getFrameValues("width")}
                        step={0.1}
                        min={0.1}
                        key={createKey(selectedIds, "width")}
                        onBlur={(e) => {
                          updateUnit("FRAME", selectedIds, {
                            width: parseFloat(parse(e.target.value)),
                          });
                        }}
                      >
                        <NumberInputField id="width" paddingInlineEnd={1} />
                      </NumberInput>
                    </HStack>
                    <HStack mt={4} spacing={5}>
                      <Checkbox
                        colorScheme={"gray"}
                        size="sm"
                        isChecked={getFrameValues("hasLeftFrame")}
                        onChange={(e) => {
                          const hasFrame = e.target.checked;
                          const newFrames = toggleHasFrame(hasFrame, COMPONENT_ALIGNMENT.LEFT, frames, selectedIds, frameThickness);
                          updateUnit("FRAMES", -1, newFrames);
                        }}
                      >
                        <Text fontSize="xs">Left frame</Text>
                      </Checkbox>
                      <Checkbox
                        colorScheme={"gray"}
                        size="sm"
                        isChecked={getFrameValues("hasRightFrame")}
                        onChange={(e) => {
                          const hasFrame = e.target.checked;
                          const newFrames = toggleHasFrame(hasFrame, COMPONENT_ALIGNMENT.RIGHT, frames, selectedIds, frameThickness);
                          updateUnit("FRAMES", -1, newFrames);
                        }}
                      >
                        <Text fontSize="xs">Right frame</Text>
                      </Checkbox>
                      {/* <Checkbox
                    colorScheme={"gray"}
                    size="sm"
                    isChecked={getFrameValues("hasFrame")}
                    disabled={component === COMPONENT_TYPES.EMPTY}
                    onChange={(e) => updateUnit("FRAME", selectedIds, { hasFrame: e.target.checked })}
                  >
                    <Text fontSize="xs">Has frame</Text>
                  </Checkbox> */}
                    </HStack>
                    <HStack mt={4} spacing={5}>
                      <Checkbox
                        colorScheme={"gray"}
                        size="sm"
                        isChecked={getFrameValues("hasCeiling")}
                        onChange={(e) =>
                          updateUnit("FRAME", selectedIds, {
                            hasCeiling: e.target.checked,
                          })
                        }
                      >
                        <Text fontSize="xs">Has ceiling</Text>
                      </Checkbox>
                      <Checkbox
                        colorScheme={"gray"}
                        size="sm"
                        isChecked={getFrameValues("hasFloor")}
                        onChange={(e) =>
                          updateUnit("FRAME", selectedIds, {
                            hasFloor: e.target.checked,
                          })
                        }
                      >
                        <Text fontSize="xs">Has floor</Text>
                      </Checkbox>
                    </HStack>
                    <HStack mt={4} spacing={5}>
                      <Checkbox
                        colorScheme={"gray"}
                        size="sm"
                        isChecked={getFrameValues("hasFlange")}
                        onChange={(e) =>
                          updateUnit("FRAME", selectedIds, {
                            hasFlange: e.target.checked,
                          })
                        }
                      >
                        <Text fontSize="xs">Has flange</Text>
                      </Checkbox>
                    </HStack>

                    <HStack mb={4} spacing="24px">
                      <Text fontSize="xs">Flange alignment</Text>
                      <ButtonGroup size="xs" isAttached colorScheme="gray" variant="outline">
                        <Button
                          variant={
                            getFrameValues("flangeAlignment") == null || getFrameValues("flangeAlignment") === COMPONENT_ALIGNMENT.LEFT
                              ? "solid"
                              : "outline"
                          }
                          onClick={() =>
                            updateUnit("FRAME", selectedIds, {
                              flangeAlignment: COMPONENT_ALIGNMENT.LEFT,
                            })
                          }
                        >
                          Left
                        </Button>
                        <Button
                          variant={getFrameValues("flangeAlignment") === COMPONENT_ALIGNMENT.TOP ? "solid" : "outline"}
                          onClick={() =>
                            updateUnit("FRAME", selectedIds, {
                              flangeAlignment: COMPONENT_ALIGNMENT.TOP,
                            })
                          }
                        >
                          Top
                        </Button>
                        <Button
                          variant={getFrameValues("flangeAlignment") === COMPONENT_ALIGNMENT.RIGHT ? "solid" : "outline"}
                          onClick={() =>
                            updateUnit("FRAME", selectedIds, {
                              flangeAlignment: COMPONENT_ALIGNMENT.RIGHT,
                            })
                          }
                        >
                          Right
                        </Button>
                      </ButtonGroup>
                    </HStack>
                    <HStack mt={4} spacing={5}>
                      <Checkbox
                        colorScheme={"gray"}
                        size="sm"
                        isChecked={getFrameValues("hasLeftWall")}
                        onChange={(e) =>
                          updateUnit("FRAME", selectedIds, {
                            hasLeftWall: e.target.checked,
                          })
                        }
                      >
                        <Text fontSize="xs">Has left wall</Text>
                      </Checkbox>
                      <Checkbox
                        colorScheme={"gray"}
                        size="sm"
                        isChecked={getFrameValues("hasRightWall")}
                        onChange={(e) =>
                          updateUnit("FRAME", selectedIds, {
                            hasRightWall: e.target.checked,
                          })
                        }
                      >
                        <Text fontSize="xs">Has right wall</Text>
                      </Checkbox>
                    </HStack>
                  </Box>

                  <Divider />
                  <Text mb={1} mt={4} mx={4} fontSize="xs">
                    <b>Door properties</b>
                  </Text>

                  <Box p={4}>
                    {Object.keys(DoorProperties).map((property, i) => {
                      const value = DoorProperties[property];
                      return (
                        <InspectorProperty
                          key={`door-property-${property}-${i}`}
                          label={property}
                          defaultValue={value}
                          inputKey={createKey(selectedIds, `${component}-${property}`)}
                          value={getComponentPropertyValues("door", property)}
                          update={(value) => {
                            updateUnit("FRAME", selectedIds, {
                              door: { [property]: property == "alignment" || property == "hinge" ? parseInt(value) : value },
                            });
                          }}
                        />
                      );
                    })}
                  </Box>

                  <Divider />
                  <Text mb={1} mt={4} mx={4} fontSize="xs">
                    <b>Component properties</b>
                  </Text>

                  <Box p={4}>
                    <HStack mb={4} spacing="24px">
                      <Text fontSize="xs">Alignment</Text>
                      <ButtonGroup size="xs" isAttached colorScheme="gray" variant="outline">
                        <Button
                          variant={getFrameValues("componentAlignment") === COMPONENT_ALIGNMENT.LEFT ? "solid" : "outline"}
                          onClick={() =>
                            updateUnit("FRAME", selectedIds, {
                              componentAlignment: COMPONENT_ALIGNMENT.LEFT,
                            })
                          }
                        >
                          Left
                        </Button>
                        {}
                        <Button
                          variant={
                            getFrameValues("componentAlignment") == null || getFrameValues("componentAlignment") === COMPONENT_ALIGNMENT.CENTER
                              ? "solid"
                              : "outline"
                          }
                          onClick={() =>
                            updateUnit("FRAME", selectedIds, {
                              componentAlignment: COMPONENT_ALIGNMENT.CENTER,
                            })
                          }
                        >
                          Center
                        </Button>
                        <Button
                          variant={getFrameValues("componentAlignment") === COMPONENT_ALIGNMENT.RIGHT ? "solid" : "outline"}
                          onClick={() =>
                            updateUnit("FRAME", selectedIds, {
                              componentAlignment: COMPONENT_ALIGNMENT.RIGHT,
                            })
                          }
                        >
                          Right
                        </Button>
                      </ButtonGroup>
                    </HStack>
                  </Box>

                  {component > -1 && (
                    <>
                      <Box p={4}>
                        {component === COMPONENT_TYPES.FILTER &&
                          Object.keys(FilterProperties).map((property) => {
                            const value = FilterProperties[property];
                            return (
                              <InspectorProperty
                                key={property}
                                label={property}
                                defaultValue={value}
                                inputKey={createKey(selectedIds, `${component}-${property}`)}
                                value={getComponentPropertyValues("filter", property)}
                                update={(value) => {
                                  updateUnit("FRAME", selectedIds, {
                                    filter: { [property]: value },
                                  });
                                }}
                              />
                            );
                          })}

                        {component === COMPONENT_TYPES.UV_LIGHTS &&
                          Object.keys(UVProperties).map((property) => {
                            const value = UVProperties[property];
                            return (
                              <InspectorProperty
                                key={property}
                                label={property}
                                defaultValue={value}
                                inputKey={createKey(selectedIds, `${component}-${property}`)}
                                value={getComponentPropertyValues("uv", property)}
                                update={(value) => {
                                  updateUnit("FRAME", selectedIds, {
                                    uv: { [property]: value },
                                  });
                                }}
                              />
                            );
                          })}

                        {component === COMPONENT_TYPES.CARBON_FILTER &&
                          Object.keys(CarbonFilterProperties).map((property) => {
                            const value = CarbonFilterProperties[property];
                            return (
                              <InspectorProperty
                                key={property}
                                label={property}
                                defaultValue={value}
                                inputKey={createKey(selectedIds, `${component}-${property}`)}
                                value={getComponentPropertyValues("carbonFilter", property)}
                                update={(value) => {
                                  updateUnit("FRAME", selectedIds, {
                                    carbonFilter: { [property]: value },
                                  });
                                }}
                              />
                            );
                          })}

                        {component === COMPONENT_TYPES.BAG_FILTER &&
                          Object.keys(BagFilterProperties).map((property) => {
                            const value = BagFilterProperties[property];
                            return (
                              <InspectorProperty
                                key={property}
                                label={property}
                                defaultValue={value}
                                inputKey={createKey(selectedIds, `${component}-${property}`)}
                                value={getComponentPropertyValues("bagFilter", property)}
                                update={(value) => {
                                  updateUnit("FRAME", selectedIds, {
                                    bagFilter: { [property]: value },
                                  });
                                }}
                              />
                            );
                          })}

                        {component === COMPONENT_TYPES.DUCT &&
                          Object.keys(DuctProperties).map((property) => {
                            const value = DuctProperties[property];
                            return (
                              <InspectorProperty
                                key={property}
                                label={property}
                                defaultValue={value}
                                selectedIds={selectedIds}
                                inputKey={createKey(selectedIds, `${component}-${property}`)}
                                value={getComponentPropertyValues("duct", property)}
                                update={(value) => {
                                  updateUnit("FRAME", selectedIds, {
                                    duct: { [property]: value },
                                  });
                                }}
                              />
                            );
                          })}

                        {component === COMPONENT_TYPES.COIL &&
                          Object.keys(CoilProperties).map((property) => {
                            const value = CoilProperties[property];
                            return (
                              <InspectorProperty
                                key={property}
                                label={property}
                                defaultValue={value}
                                selectedIds={selectedIds}
                                inputKey={createKey(selectedIds, `${component}-${property}`)}
                                value={getComponentPropertyValues("coil", property)}
                                update={(value) => {
                                  updateUnit("FRAME", selectedIds, {
                                    coil: { [property]: value },
                                  });
                                }}
                              />
                            );
                          })}

                        {component === COMPONENT_TYPES.FAN &&
                          Object.keys(FanProperties).map((property) => {
                            const value = FanProperties[property];
                            return (
                              <InspectorProperty
                                key={property}
                                label={property}
                                defaultValue={value}
                                selectedIds={selectedIds}
                                inputKey={createKey(selectedIds, `${component}-${property}`)}
                                value={getComponentPropertyValues("fan", property)}
                                update={(value) => {
                                  updateUnit("FRAME", selectedIds, {
                                    fan: { [property]: value },
                                  });
                                }}
                              />
                            );
                          })}
                      </Box>
                    </>
                  )}

                  <Divider />
                  <Text mb={1} mt={4} mx={4} fontSize="xs">
                    <b>Select component</b>
                  </Text>
                  <SimpleGrid p={4} columns={2} spacing={4}>
                    <>
                      <Button
                        colorScheme="gray"
                        variant="outline"
                        borderStyle={"dashed"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.EMPTY,
                            hasFrame: true,
                          })
                        }
                      >
                        None
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.COIL ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.COIL ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.COIL,
                          })
                        }
                      >
                        Coil
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.FAN ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.FAN ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.FAN,
                          })
                        }
                      >
                        Fan
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.FILTER ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.FILTER ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.FILTER,
                          })
                        }
                      >
                        Filter
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.DAMPER ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.DAMPER ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.DAMPER,
                          })
                        }
                      >
                        Damper
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.UV_LIGHTS ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.UV_LIGHTS ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.UV_LIGHTS,
                          })
                        }
                      >
                        UV Lights
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.CARBON_FILTER ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.CARBON_FILTER ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.CARBON_FILTER,
                          })
                        }
                      >
                        Carbon Filter
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.OZONE ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.OZONE ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.OZONE,
                          })
                        }
                      >
                        Ozone
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.ODOUR_NEUTRALISER ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.ODOUR_NEUTRALISER ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.ODOUR_NEUTRALISER,
                          })
                        }
                      >
                        Odour Neutraliser
                      </Button>
                      <Button
                        colorScheme={component === COMPONENT_TYPES.BAG_FILTER ? "teal" : "gray"}
                        variant={component === COMPONENT_TYPES.BAG_FILTER ? "solid" : "outline"}
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.BAG_FILTER,
                          })
                        }
                      >
                        Bag Filter
                      </Button>
                      {/* <Button
                        colorScheme={
                          component === COMPONENT_TYPES.DUCT ? "teal" : "gray"
                        }
                        variant={
                          component === COMPONENT_TYPES.DUCT
                            ? "solid"
                            : "outline"
                        }
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.DUCT,
                          })
                        }
                      >
                        Duct
                      </Button>
                      <Button
                        colorScheme={
                          component === COMPONENT_TYPES.PLATE_HEAT_EXCHANGER
                            ? "teal"
                            : "gray"
                        }
                        variant={
                          component === COMPONENT_TYPES.PLATE_HEAT_EXCHANGER
                            ? "solid"
                            : "outline"
                        }
                        h={70}
                        size="xs"
                        onClick={() => {
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.PLATE_HEAT_EXCHANGER,
                          });
                        }}
                      >
                        Plate HX
                      </Button>
                      <Button
                        colorScheme={
                          component === COMPONENT_TYPES.ROTARY_HEAT_EXCHANGER
                            ? "teal"
                            : "gray"
                        }
                        variant={
                          component === COMPONENT_TYPES.ROTARY_HEAT_EXCHANGER
                            ? "solid"
                            : "outline"
                        }
                        h={70}
                        size="xs"
                        onClick={() =>
                          updateUnit("FRAME", selectedIds, {
                            component: COMPONENT_TYPES.ROTARY_HEAT_EXCHANGER,
                          })
                        }
                      >
                        Rotary HX
                      </Button> */}
                    </>
                  </SimpleGrid>
                </>
              )}
            </TabPanel>
          )}
          <TabPanel px={0}>
            <Text mb={1} mx={4} fontSize="xs">
              <b>Dimensions</b>
            </Text>
            <Box p={4}>
              <HStack spacing="24px" mt={4}>
                <Text fontSize="xs">Frame thickness</Text>
                <NumberInput
                  size="xs"
                  defaultValue={frameThickness}
                  step={0.001}
                  min={0.001}
                  display="flex"
                  key={createKey(frames.id, "FRAME_THICKNESS")}
                  onBlur={(e) => {
                    setFrameThickness(parseFloat(parse(e.target.value)));
                  }}
                >
                  <NumberInputField id="frameThickness" />
                </NumberInput>
              </HStack>
            </Box>
            <Divider />
            <Text mb={1} mt={4} mx={4} fontSize="xs">
              <b>Add a frame</b>
            </Text>
            <SimpleGrid p={4} columns={2} spacing={4}>
              <>
                <Button colorScheme="gray" variant="outline" borderStyle={"dashed"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.EMPTY)}>
                  None
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.COIL)}>
                  Coil
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.FAN)}>
                  Fan
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.FILTER)}>
                  Filter
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.DAMPER)}>
                  Damper
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.UV_LIGHTS)}>
                  UV Lights
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.CARBON_FILTER)}>
                  Carbon Filter
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.OZONE)}>
                  Ozone
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.ODOUR_NEUTRALISER)}>
                  Odour Neutraliser
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.BAG_FILTER)}>
                  Bag Filter
                </Button>
                <Button
                  colorScheme={"gray"}
                  variant={"outline"}
                  h={70}
                  size="xs"
                  onClick={() => {
                    addFrame(COMPONENT_TYPES.PLATE_HEAT_EXCHANGER);
                  }}
                >
                  Plate HX
                </Button>
                <Button colorScheme={"gray"} variant={"outline"} h={70} size="xs" onClick={() => addFrame(COMPONENT_TYPES.ROTARY_HEAT_EXCHANGER)}>
                  Rotary HX
                </Button>
              </>
            </SimpleGrid>
          </TabPanel>
          <TabPanel></TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

const camel2title = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

function InspectorProperty({ label, defaultValue, value, update, inputKey }) {
  const options = defaultValue.options;
  const valueType = typeof defaultValue;

  return (
    <HStack mb={4} spacing="18px">
      <Text fontSize="xs" w={300}>
        {camel2title(label)}
      </Text>
      {options ? (
        <Select
          value={value || defaultValue.value}
          onChange={(e) => {
            update(e.target.value);
          }}
          size="xs"
        >
          {options.map((option, i) => {
            return (
              <option key={`${option}-${i}`} value={option.value != undefined ? option.value : option}>
                {option.text != undefined ? option.text : option}
              </option>
            );
          })}
        </Select>
      ) : valueType === "boolean" ? (
        <Checkbox
          colorScheme={"gray"}
          size="sm"
          isChecked={value != undefined ? value : defaultValue}
          onChange={(e) => {
            update(e.target.checked);
          }}
        >
          {/* <Text fontSize="xs">{label}</Text> */}
        </Checkbox>
      ) : valueType === "number" ? (
        <NumberInput
          size="xs"
          defaultValue={value || defaultValue}
          display="flex"
          key={inputKey}
          onBlur={(e) => {
            update(parseFloat(parse(e.target.value)));
          }}
        >
          <NumberInputField id={label} />
        </NumberInput>
      ) : (
        <Input size="xs" defaultValue={value || defaultValue} display="flex" onBlur={(e) => {}}></Input>
      )}
    </HStack>
  );
}
