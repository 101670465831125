import { Edges } from "@react-three/drei";

export default function Dampers(props) {
  const dampers = [];
  for (let i = 0; i < 5; i++) {
    dampers.push(
      <mesh
        key={i}
        castShadow
        receiveShadow
        position={[props.position[0], props.position[1] - props.bladeSize[0] * i, props.position[2]]}
        rotation-z={props.rotation}
      >
        <boxGeometry args={props.bladeSize} />
        <meshStandardMaterial color={"#333333"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
    );
  }

  return dampers;
}
