import { useMemo } from "react";
import { fromMM } from "../Utils/frameUtils";
import { buildPanel } from "../Utils/geometryUtils";
import ControlBoxProperties from "./Properties/ControlBoxProperties";
import { Edges } from "@react-three/drei";

export default function ControlBox({ frame, position }) {
  const { controlBox } = frame;
  const offset = fromMM(40);
  const frameLength = fromMM(frame.length);
  const frameHeight = fromMM(frame.height);

  const boxLength = fromMM(controlBox?.length || ControlBoxProperties.length);
  const boxHeight = fromMM(controlBox?.height || ControlBoxProperties.height);
  const boxWidth = fromMM(controlBox?.width || ControlBoxProperties.width);
  const boxPosition = [
    position[0] + frameLength / 2 - boxLength / 2 - offset,
    position[1] + frameHeight / 2 - boxHeight / 2 - offset,
    position[2] + boxWidth / 2,
  ];

  const geom = useMemo(() => {
    return buildPanel(boxLength, boxHeight, boxWidth);
  }, [boxLength, boxHeight, boxWidth]);

  return (
    <group>
      <mesh geometry={geom} position={boxPosition} receiveShadow castShadow>
        <meshStandardMaterial color="Grey" />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Black" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
