import { Box, Button, ButtonGroup, Checkbox, HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from "@chakra-ui/react";
import React from "react";
import { TOOL_MODE } from "./Constants";
import { calculateAttached } from "./Utils/frameUtils";
import { useStore } from "./Store/zustandStore";

export default function Header({ frames, updateUnit, isOrthographic, setIsOrthographic, autoRotate, setAutoRotate, resetControls }) {
  const [
    toolMode,
    setToolMode,
    selectedUUID,
    showDimensions,
    setShowDimensions,
    frameThickness,
    showPanels,
    setShowPanels,
    panelOpacity,
    setPanelOpacity,
    doorsOpen,
    setDoorsOpen,
  ] = useStore((state) => [
    state.toolMode,
    state.setToolMode,
    state.selectedUUID,
    state.showDimensions,
    state.setShowDimensions,
    state.frameThickness,
    state.showPanels,
    state.setShowPanels,
    state.panelOpacity,
    state.setPanelOpacity,
    state.doorsOpen,
    state.setDoorsOpen,
  ]);

  function recalculateAttached() {
    updateUnit("FRAMES", -1, calculateAttached(frames, frameThickness));
  }

  return (
    <div
      style={{
        height: "50px",
        background: "white",
        borderBottom: "1px solid #dedede",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box px={4}>
        <HStack spacing="24px">
          {/* <Text fontFamily={"monospace"}>[AHU Designer Prototype]</Text> */}
          <HStack spacing="12px">
            <Text fontSize="xs">
              <b>Camera mode</b>
            </Text>
            <ButtonGroup variant="outline" isAttached>
              <Button
                size="xs"
                onClick={() => setIsOrthographic(false)}
                colorScheme={isOrthographic ? "gray" : "teal"}
                variant={isOrthographic ? "outline" : "solid"}
              >
                Perspective
              </Button>
              <Button
                size="xs"
                onClick={() => setIsOrthographic(true)}
                colorScheme={!isOrthographic ? "gray" : "teal"}
                variant={!isOrthographic ? "outline" : "solid"}
              >
                Orthographic
              </Button>
            </ButtonGroup>
            <Button size="xs" onClick={resetControls} colorScheme={"red"} variant={"solid"}>
              Reset View
            </Button>
            <Checkbox colorScheme={"gray"} size="sm" isChecked={autoRotate} onChange={(e) => setAutoRotate(e.target.checked)}>
              <Text fontSize="xs">Auto rotate</Text>
            </Checkbox>

            <ButtonGroup size="xs" isAttached colorScheme="gray" variant="outline">
              <Button variant={toolMode === TOOL_MODE.SELECTION ? "solid" : "outline"} onClick={() => setToolMode(TOOL_MODE.SELECTION)}>
                Select
              </Button>
              <Button
                variant={toolMode === TOOL_MODE.MOVE ? "solid" : "outline"}
                onClick={() => setToolMode(TOOL_MODE.MOVE)}
                disabled={selectedUUID == null}
              >
                Move
              </Button>
            </ButtonGroup>
            <Checkbox colorScheme={"gray"} size="sm" isChecked={showDimensions} onChange={(e) => setShowDimensions(e.target.checked)}>
              <Text fontSize="xs">Show dimensions</Text>
            </Checkbox>
            <Checkbox colorScheme={"gray"} size="sm" isChecked={showPanels} onChange={(e) => setShowPanels(e.target.checked)}>
              <Text fontSize="xs">Show panels</Text>
            </Checkbox>
            <Text fontSize="xs">Opacity</Text>
            <Slider
              aria-label="slider-ex-1"
              defaultValue={panelOpacity}
              width={100}
              min={0}
              max={1}
              step={0.1}
              colorScheme="teal"
              onChange={(v) => setPanelOpacity(v)}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Button size="xs" onClick={() => setDoorsOpen(!doorsOpen)} colorScheme={"teal"} variant={"solid"}>
              {doorsOpen ? "Close doors" : "Open doors"}
            </Button>
          </HStack>
        </HStack>
      </Box>
    </div>
  );
}
