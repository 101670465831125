import { useMemo, useRef } from "react";
import { useSpring, animated, config } from "@react-spring/three";
import { fromMM } from "../Utils/frameUtils";
import { buildPanel } from "../Utils/geometryUtils";
import DoorProperties from "./Properties/DoorProperties";
import * as THREE from "three";
import { Edges } from "@react-three/drei";

export default function Door({ door, height, thickness, position, opacity, open, getColour }) {
  const length = fromMM(door.length || DoorProperties.length);
  const hinge = door.hinge || DoorProperties.hinge.value;
  const doorMesh = useRef();

  const { rotation } = useSpring({
    rotation: open ? (Math.PI / 2) * hinge : 0,
    config: config.stiff,
  });

  const geom = useMemo(() => {
    const panel = buildPanel(length, height, thickness);
    panel.applyMatrix4(new THREE.Matrix4().makeTranslation((length / 2) * hinge * -1, 0, -thickness / 2));
    return panel;
  }, [length, height, hinge]);

  return (
    <animated.mesh
      geometry={geom}
      ref={doorMesh}
      position={[position[0] - (length / 2) * hinge * -1, position[1], position[2] + thickness / 2]}
      rotation-y={rotation}
      receiveShadow
      castShadow
    >
      <meshStandardMaterial color={getColour("DarkGrey")} transparent={true} opacity={opacity} />
      <Edges scale={1} renderOrder={1000}>
        <meshBasicMaterial transparent color="Grey" widthTest={true} />
      </Edges>
    </animated.mesh>
  );
}
