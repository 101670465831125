import { Edges } from "@react-three/drei";
import { COMPONENT_ALIGNMENT } from "../Constants";
import { fromMM } from "../Utils/frameUtils";

export default function CarbonFilter({ frame }) {
  const frameWidth = fromMM(frame.width) * 0.9;
  const frameLength = fromMM(frame.length);
  const frameHeight = fromMM(frame.height);

  const requiredFilters = frame.carbonFilter?.requiredFilters || 1;
  const slopeHeight = frameHeight / requiredFilters;
  const caps = 0.01;

  let filters = [];

  for (let i = 0; i < requiredFilters; i++) {
    filters.push(
      <FilterSlope
        key={`carbon-filter-${i}`}
        position={[0, slopeHeight / 2 + i * slopeHeight - frameHeight / 2, 0]}
        length={frameLength}
        height={slopeHeight / 2}
        width={frameWidth}
      />
    );
    // filters.push(
    //   <mesh castShadow receiveShadow position={[-frameLength / 2 + caps / 2, slopeHeight / 2 + i * slopeHeight - frameHeight / 2, 0]}>
    //     <boxGeometry args={[caps, caps, frameWidth]} />
    //     <meshStandardMaterial color={"#333333"} />
    //     <Edges scale={1} renderOrder={1000}>
    //       <meshBasicMaterial transparent color="#111" depthTest={true} />
    //     </Edges>
    //   </mesh>
    // );
  }

  return (
    <group dispose={null} rotation-y={frame.componentAlignment == COMPONENT_ALIGNMENT.RIGHT ? Math.PI : 0}>
      {filters}
    </group>
  );
}

function FilterSlope({ length, height, width, position }) {
  const lengthOfFilter = Math.sqrt(height ** 2 + length ** 2);
  const angleOfFilter = Math.atan(height / length);

  return (
    <group dispose={null} position={position}>
      <mesh castShadow receiveShadow rotation-z={angleOfFilter} position={[0, -height / 2, 0]}>
        <boxGeometry args={[lengthOfFilter, 0.01, width]} />
        <meshStandardMaterial color={"#333333"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
      <mesh castShadow receiveShadow rotation-z={-angleOfFilter} position={[0, height / 2, 0]}>
        <boxGeometry args={[lengthOfFilter, 0.01, width]} />
        <meshStandardMaterial color={"#333333"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
