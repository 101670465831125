import * as THREE from "three";

import { Edges } from "@react-three/drei";
import { Fragment, useMemo } from "react";
import { calculationComponentPosition, fromMM } from "../Utils/frameUtils";
import { buildDuctPlateWithHole, buildPanel } from "../Utils/geometryUtils";
import { COMPONENT_ALIGNMENT } from "../Constants";
import UVProperties from "./Properties/UVProperties";
import { useStore } from "../Store/zustandStore";

const bracketLength = 0.04;
const bracketWidth = 0.01;

export default function UVLights({ frame, getColour }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);
  const { uv } = frame;
  const requiredLights = uv?.requiredLights || 2;
  const tubeWidth = fromMM(uv?.tubeWidth || 480);
  const frameHeight = fromMM(frame.height);
  const frameWidth = fromMM(frame.width);
  const distance = frameHeight / requiredLights;
  const requiredColumns = Math.floor(frameWidth / tubeWidth);
  const columnDistance = frameWidth / requiredColumns;

  const boxLength = fromMM(uv?.boxLength || UVProperties.boxLength);
  const boxHeight = fromMM(uv?.boxHeight || UVProperties.boxHeight);
  const boxWidth = fromMM(uv?.boxWidth || UVProperties.boxWidth);
  const bottom_offset = frameHeight / 2 - boxHeight / 2;
  const boxPosition = [0, -frameHeight / 2 + boxHeight / 2 + bottom_offset, -frameWidth / 2 - boxWidth / 2 - frameThickness];

  let tubes = [];

  for (let c = 0; c < requiredColumns; c++) {
    for (let i = 0; i < requiredLights; i++) {
      tubes.push(
        <UVLight
          key={`uv-light${i}-${c}`}
          position={[0, distance / 2 + i * distance - frameHeight / 2, columnDistance / 2 + c * columnDistance - frameWidth / 2]}
          width={tubeWidth}
        />
      );
    }
  }

  const geom = useMemo(() => {
    return buildDuctPlateWithHole(frameHeight, frameWidth, false, frameWidth * 0.8, frameHeight * 0.8, 0, frameHeight * 0.1);
  }, [frameHeight, frameWidth]);

  const boxGeom = useMemo(() => {
    return buildPanel(boxLength, boxHeight, boxWidth);
  }, [boxLength, boxHeight, boxWidth]);

  return (
    <group>
      <mesh geometry={boxGeom} position={boxPosition} receiveShadow castShadow>
        <meshStandardMaterial color="Grey" />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
      <group position={calculationComponentPosition(bracketLength, frame)}>
        <mesh geometry={geom} rotation-y={Math.PI / 2} position={[((frame.componentAlignment == COMPONENT_ALIGNMENT.LEFT ? -1 : 1) * bracketLength) / 2, 0, 0]}>
          <meshStandardMaterial color={getColour("DarkGrey")} />
        </mesh>
        {tubes}
      </group>
    </group>
  );
}

function UVLight({ position, width }) {
  const tubeThickness = 0.02;
  const bracketOffset = 0.02;

  return (
    <group position={position}>
      // Main tube
      <mesh castShadow receiveShadow position={[0, 0, 0]} rotation-x={Math.PI / 2}>
        <cylinderGeometry args={[tubeThickness / 2, tubeThickness / 2, width, 8]} />
        <meshLambertMaterial color={new THREE.Color("#76d0ff")} emissive={new THREE.Color(0, 0, 1)} emissiveIntensity={2} toneMapped={false} />
      </mesh>
      // Tube brackets
      <UVLightBracket tubeThickness={tubeThickness} position={[0, 0, -bracketOffset + width / 2]} />
      <UVLightBracket tubeThickness={tubeThickness} position={[0, 0, bracketOffset + -width / 2]} />
    </group>
  );
}

function UVLightBracket({ tubeThickness, position }) {
  const bracketHeight = 0.002;
  const topBracket = [position[0], position[1] + tubeThickness / 2, position[2]];
  const bottomBracket = [position[0], position[1] - tubeThickness / 2, position[2]];

  return (
    <>
      <mesh castShadow receiveShadow position={topBracket}>
        <boxGeometry args={[bracketLength, bracketHeight, bracketWidth]} />
        <meshStandardMaterial color={"#333333"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
      <mesh castShadow receiveShadow position={bottomBracket}>
        <boxGeometry args={[bracketLength, bracketHeight, bracketWidth]} />
        <meshStandardMaterial color={"#333333"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
    </>
  );
}
